import React, { useEffect, useRef, useState } from "react"
import EditorAdd from "./EditorAdd";

const Product_tabs = ({productTabs, fulldesc}) => {

    const [selectedBtn, setSelectedBtn] = useState('')

    useEffect(() => {
        if(productTabs) {
            if(fulldesc?.blocks?.length > 0) {
                setSelectedBtn('desc')
            } else {
                setSelectedBtn(productTabs[0]?.id)
            }
        }
    },[productTabs])
    
    return (
        <>
        {
        <div className="product_tabs">
            {fulldesc?.blocks?.length > 0 &&
                <button className={`h-14-600-i ${selectedBtn === 'desc' ? 'active' : ''}`} key={'desc'} onClick={() => setSelectedBtn('desc')}>Описание</button>
            }
            {productTabs?.map((el) => (
                <button className={`h-14-600-i ${selectedBtn === el.id ? 'active' : ''}`} key={el.id} onClick={() => setSelectedBtn(el.id)}>{el.name}</button>
            ))}
        </div>}
        {(selectedBtn !== '' && selectedBtn !== undefined) &&
            (selectedBtn === 'desc' ?
                <div className="product-info-block">

                    <div className="col-15">
                        <div className="h-20-400-i">Описание</div>
                        <span style={{borderBottom: '1px solid #D0D5DD'}} />
                    </div>
                    <div className="h-14-400-i">
                        <EditorAdd blocks={fulldesc?.blocks}></EditorAdd>
                    </div>

                </div>
                :
                <div className="product-info-block">
    
                    <div className="col-15">
                        <div className="h-20-400-i">{productTabs?.find(obj => obj.id === selectedBtn)?.name}</div>
                        <span style={{borderBottom: '1px solid #D0D5DD'}} />
                    </div>
                    <div className="h-14-400-i">
                        <EditorAdd blocks={productTabs?.find(obj => obj.id === selectedBtn)?.value?.blocks}></EditorAdd>
                    </div>
    
                </div>
            )
        }
        </>
    )
}

export default Product_tabs;