import React, { useContext, useEffect, useRef, useState } from "react"
import { Context } from "../App";
import { products } from "../utils/consts";
import { useLocation, useNavigate } from "react-router";
import { useFetching } from "../hooks/UseFetching";
import StoreLsidebar from "./components/StoreLsidebar";
import ProductExample from "./components/Product_example";
import GetStore from "../API/GetStore";
import CustomSelect from "./components/Custom_select";
import Pagination from "./components/Pagination";
import ProductExample_line from "./components/ProductExample_line";
import img_pack from "../img/img";
import SotreSearchBlock from "./components/StoreSearchBlock";
import FilterSearchBlock from "./components/FilterSearchBlock";

const StorePage = () => {
    
    const location = useLocation()

    let queryState = location.state?.query
    let searchInitialData = location.state?.searchInitialData
    let initialCat = location.state?.initialCat
    let initialBrand = location.state?.initialBrand

    const {windowWidth, setOrDeleteParam, queryParams} = useContext(Context)
    const navigate = useNavigate()
    const [productsAll, setProductsAll] = useState([])
    const [activeSort, setActiveSort] = useState(queryParams.get('sort_by') ? [(typeof queryParams.get('sort_by') === 'string' && JSON.parse(queryParams.get('sort_by')) )] : [])
    const [rangePrice, setRangePrice] = useState([queryParams.get('rangePrice[0]') || '', queryParams.get('rangePrice[1]') || '']);
    const [query, setQuery] = useState(queryParams.get('query') || '');
    const [AllParams, setAllParams] = useState({
        // selected1:  queryParams.get('sel') === 'tires' ? false : true,
        tires: {
            width: queryParams.get('tiresWidth') ? [(typeof queryParams.get('tiresWidth') === 'string' && JSON.parse(queryParams.get('tiresWidth')) )] : [],
            profile: queryParams.get('tiresProfile') ? [(typeof queryParams.get('tiresProfile') === 'string' && JSON.parse(queryParams.get('tiresProfile')) )] : [],
            diameter: queryParams.get('tiresDiameter') ? [(typeof queryParams.get('tiresDiameter') === 'string' && JSON.parse(queryParams.get('tiresDiameter')) )] : [],
            seasonality: queryParams.get('tiresSeasonality') ? [(typeof queryParams.get('tiresSeasonality') === 'string' && JSON.parse(queryParams.get('tiresSeasonality')) )] : [],
            type: queryParams.get('tiresType') ? [(typeof queryParams.get('tiresType') === 'string' && JSON.parse(queryParams.get('tiresType')) )] : [],
        },
        disks: {
            width: queryParams.get('disksWidth') ? [(typeof queryParams.get('disksWidth') === 'string' && JSON.parse(queryParams.get('disksWidth')) )] : [],
            diameter: queryParams.get('disksDiameter') ? [(typeof queryParams.get('disksDiameter') === 'string' && JSON.parse(queryParams.get('disksDiameter')) )] : [],
            et: queryParams.get('disksEt') ? [(typeof queryParams.get('disksEt') === 'string' && JSON.parse(queryParams.get('disksEt')) )] : [],
            pcd: queryParams.get('disksPCD') ? [(typeof queryParams.get('disksPCD') === 'string' && JSON.parse(queryParams.get('disksPCD')) )] : [],
            hub: queryParams.get('disksHub') ? [(typeof queryParams.get('disksHub') === 'string' && JSON.parse(queryParams.get('disksHub')) )] : [],
            type: queryParams.get('disksType') ? [(typeof queryParams.get('disksType') === 'string' && JSON.parse(queryParams.get('disksType')) )] : [],
        }
    });
    const [maxPrice, setMaxPrice] = useState('');

    const [mobileMenu1, setMobileMenu1] = useState(false);
    const [mobileMenu2, setMobileMenu2] = useState(false);

    const [viewSelect, setViewSelect] = useState(false);

    const [selectedCat, setSelectedCat] = useState((typeof queryParams.get('cat') === 'string' && JSON.parse(queryParams.get('cat')) ) || {name:'' , slug: ''});
    const [selectedBrand, setSelectedBrand] = useState((typeof queryParams.get('brand') === 'string' && JSON.parse(queryParams.get('brand')) ) || {name: '', slug: ''});
    
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(null)
    const [totalProducts, setTotalProducts] = useState(null)

    const [filtersUsed, setFiltersUsed] = useState([])

    useEffect(() => {
        if(queryState !== '' && queryState !== undefined) {
            setQuery(queryState)
            setIsFilterChange(true)
            location.state.query = ''
            window.history.replaceState({}, '')
        }
    },[queryState])

    // Получение всех товаров
    const [UsersProductsAll, storeLoading] = useFetching( async (productData, page) => {
        const res = await GetStore.UsersProductsAll(productData, page)
        // console.log('UsersProductsAll complete:' , res.data)
        setProductsAll(res.data?.result)
        setTotalPages(res.data?.total_pages)
        setTotalProducts(res.data?.total)
        setMaxPrice(res.data?.max_price)

        // Применяем к каждому параметру:
        setOrDeleteParam('query', query);
        setOrDeleteParam('rangePrice[0]', rangePrice[0]);
        setOrDeleteParam('rangePrice[1]', rangePrice[1]);
        setOrDeleteParam('cat', selectedCat);
        setOrDeleteParam('brand', selectedBrand);
        setOrDeleteParam('sort_by', activeSort[0]);
        // setOrDeleteParam('sel', (AllParams?.selected1 ? 'disks' : 'tires') );
        setOrDeleteParam('tiresWidth', AllParams?.tires?.width?.[0]);
        setOrDeleteParam('tiresProfile', AllParams?.tires?.profile?.[0]);
        setOrDeleteParam('tiresDiameter', AllParams?.tires?.diameter?.[0]);
        setOrDeleteParam('tiresSeasonality', AllParams?.tires?.seasonality?.[0]);
        setOrDeleteParam('tiresType', AllParams?.tires?.type?.[0]);
        setOrDeleteParam('disksWidth', AllParams?.disks?.width?.[0]);
        setOrDeleteParam('disksDiameter', AllParams?.disks?.diameter?.[0]);
        setOrDeleteParam('disksEt', AllParams?.disks?.et?.[0]);
        setOrDeleteParam('disksPCD', AllParams?.disks?.pcd?.[0]);
        setOrDeleteParam('disksHub', AllParams?.disks?.hub?.[0]);
        setOrDeleteParam('disksType', AllParams?.disks?.type?.[0]);

        navigate(`?${queryParams.toString()}`);

        setFiltersUsed([
            {value: query || '', name: 'Поиск', slug: 'query'},
            {value: rangePrice[0] || '', name: 'Цена от', slug: 'rangePrice[0]'},
            {value: rangePrice[1] || '', name: 'Цена до', slug: 'rangePrice[1]'},
            {value: selectedCat?.name || '', name: 'Категория', slug: 'cat'},
            {value: selectedBrand?.name || '', name: 'Бренд', slug: 'brand'},
            {value: activeSort[0]?.name || '', name: 'Сортировка', slug: 'sort_by'},
            { value: AllParams?.tires?.width?.[0]?.value || '', name: 'Ширина шин', slug: 'tiresWidth' },
            { value: AllParams?.tires?.profile?.[0]?.value || '', name: 'Профиль шин', slug: 'tiresProfile' },
            { value: AllParams?.tires?.diameter?.[0]?.value || '', name: 'Диаметр шин', slug: 'tiresDiameter' },
            { value: AllParams?.tires?.seasonality?.[0]?.value || '', name: 'Сезонность шин', slug: 'tiresSeasonality' },
            { value: AllParams?.tires?.type?.[0]?.value || '', name: 'Шипы шин', slug: 'tiresType' },
            { value: AllParams?.disks?.width?.[0]?.value || '', name: 'Ширина дисков', slug: 'disksWidth' },
            { value: AllParams?.disks?.diameter?.[0]?.value || '', name: 'Диаметр дисков', slug: 'disksDiameter' },
            { value: AllParams?.disks?.et?.[0]?.value || '', name: 'ET (вылет) дисков', slug: 'disksEt' },
            { value: AllParams?.disks?.pcd?.[0]?.value || '', name: 'PCD (крепёж) дисков', slug: 'disksPCD' },
            { value: AllParams?.disks?.hub?.[0]?.value || '', name: 'Ступица дисков', slug: 'disksHub' },
            { value: AllParams?.disks?.type?.[0]?.value || '', name: 'Тип дисков', slug: 'disksType' },
            // ...(AllParams?.selected1 ? [] : [
            // ]),
            // ...(AllParams?.selected1 ? [
            // ] : []),
        ])
    })
    const prevPageRef = useRef(currentPage);
    useEffect(() => {
        if(queryState === undefined) {
            if(searchInitialData === undefined && initialBrand === undefined && initialCat === undefined) {
                if(queryParams.get('page') && +queryParams.get('page') > 1) {
                    setCurrentPage(+queryParams.get('page'))
                } else {
                    sortingProducts()
                }
            }
        }
    },[])
    useEffect(() => {
        if (prevPageRef.current !== currentPage) {
            setOrDeleteParam('page', currentPage);
            sortingProducts(currentPage);
        }
        prevPageRef.current = currentPage;
    }, [currentPage]);

    const prevValues = useRef({ activeSort, selectedCat, selectedBrand, AllParams });
    useEffect(() => {
        if (prevValues.current.activeSort !== activeSort) {
            sortingProducts();
            prevValues.current.activeSort = activeSort;
        }
    },[activeSort])
    useEffect(() => {
        if (prevValues.current.selectedCat !== selectedCat || prevValues.current.selectedBrand !== selectedBrand || prevValues.current.AllParams !== AllParams) {
            sortingProducts();
            prevValues.current.selectedCat = selectedCat;
            prevValues.current.selectedBrand = selectedBrand;
            prevValues.current.AllParams = AllParams;
        }
    },[selectedCat, selectedBrand, AllParams])
    
    // Сортировка по цене 
    function sortingProducts(page) {
        const formdata = new FormData();
        formdata.append("query", query || ''); //поиск по тексту
        formdata.append("price_from", rangePrice[0] || ''); //цена от
        formdata.append("price_to", rangePrice[1] || ''); //цена до
        formdata.append("category", selectedCat?.slug || ''); //категория (если есть потомки, то отображаются товары потомков в т.ч.)
        formdata.append("brand", selectedBrand?.slug || ''); //slug бренда
        formdata.append("sort_by", activeSort[0]?.value || ''); //created_desc / created_asc / price_desc / price_asc
        formdata.append("item_quantity", 12); //кол-во итемов для вывода (дефолт 12)
        formdata.append("tire_width", AllParams?.tires?.width?.[0]?.value || '');
        formdata.append("tire_height", AllParams?.tires?.profile?.[0]?.value || '');
        formdata.append("tire_diameter", AllParams?.tires?.diameter?.[0]?.value || '');
        formdata.append("tire_season", AllParams?.tires?.seasonality?.[0]?.value || '');
        formdata.append("tire_thorn", AllParams?.tires?.type?.[0]?.value || '');
        formdata.append("disk_width", AllParams?.disks?.width?.[0]?.value || '');
        formdata.append("disk_diameter", AllParams?.disks?.diameter?.[0]?.value || '');
        formdata.append("disk_et", AllParams?.disks?.et?.[0]?.value || '');
        formdata.append("disk_pcd", AllParams?.disks?.pcd?.[0]?.value || '');
        formdata.append("disk_hub", AllParams?.disks?.hub?.[0]?.value || '');
        formdata.append("disk_type", AllParams?.disks?.type?.[0]?.value || '');
        // if(!(AllParams?.selected1)) {// шины
        // } else {//диски
        // }
        if(page === undefined || page === '') {
            page = 1;
            if(currentPage !== 1) {
                console.log('asd')
                setCurrentPage(1)
            } else {
                UsersProductsAll(formdata, page)
            }
        } else {
            UsersProductsAll(formdata, page)
        }
    }

    useEffect(() => {
        if(windowWidth<= 1000) {
            setViewSelect(true)
        }
    },[windowWidth])

    const [isFilterChange, setIsFilterChange] = useState(false);

    function filterDel(slug) {
        const updates = {
            query: () => {
                setQuery('');
                setIsFilterChange(true);
            },
            'rangePrice[0]': () => {
                setRangePrice(['', '']);
                setIsFilterChange(true);
            },
            'rangePrice[1]': () => {
                setRangePrice(['', '']);
                setIsFilterChange(true);
            },
            cat: () => {
                setSelectedCat({name: '', slug: ''})
            },
            brand: () => {
                setSelectedBrand({name: '', slug: ''});
            },
            sort_by: () => setActiveSort([]),
            tiresWidth: () => {
                setAllParams(prevState => ({
                    ...prevState,
                    tires: { ...prevState.tires, width: [] },
                }));
            },
            tiresProfile: () => {
                setAllParams(prevState => ({
                    ...prevState,
                    tires: { ...prevState.tires, profile: [] },
                }));
            },
            tiresDiameter: () => {
                setAllParams(prevState => ({
                    ...prevState,
                    tires: { ...prevState.tires, diameter: [] },
                }));
            },
            tiresSeasonality: () => {
                setAllParams(prevState => ({
                    ...prevState,
                    tires: { ...prevState.tires, seasonality: [] },
                }));
            },
            tiresType: () => {
                setAllParams(prevState => ({
                    ...prevState,
                    tires: { ...prevState.tires, type: [] },
                }));
            },
            disksWidth: () => {
                setAllParams(prevState => ({
                    ...prevState,
                    disks: { ...prevState.disks, width: [] },
                }));
            },
            disksDiameter: () => {
                setAllParams(prevState => ({
                    ...prevState,
                    disks: { ...prevState.disks, diameter: [] },
                }));
            },
            disksPCD: () => {
                setAllParams(prevState => ({
                    ...prevState,
                    disks: { ...prevState.disks, pcd: [] },
                }));
            },
            disksEt: () => {
                setAllParams(prevState => ({
                    ...prevState,
                    disks: { ...prevState.disks, et: [] },
                }));
            },
            disksHub: () => {
                setAllParams(prevState => ({
                    ...prevState,
                    disks: { ...prevState.disks, hub: [] },
                }));
            },
            disksType: () => {
                setAllParams(prevState => ({
                    ...prevState,
                    disks: { ...prevState.disks, type: [] },
                }));
            },
        };
        const updateFunction = updates[slug];
        if (updateFunction) {
            updateFunction();
        }
    }

    useEffect(() => {
        if(isFilterChange) {
            // console.log('isFilterChange')
            setIsFilterChange(false)
            sortingProducts()
        }
    },[query, rangePrice])

    return (
        <>
        {windowWidth <= 1000 &&
        <div className={`overlay ${mobileMenu1 ? 'open' : ''}`} onClick={() => setMobileMenu1(false)}>
            <div className={`mobile_menu ${mobileMenu1 ? 'open' : ''}`} onClick={e => e.stopPropagation()} style={{maxWidth: '300px', width: '100%'}}>
                <div className="mobile_menu_content">
                    <button className="close-btn" onClick={() => setMobileMenu1(false)} style={{position: 'absolute', right: '16px'}}><img src={img_pack.close_icon} alt="close_icon"/></button>
                    <div style={{padding: '10px 16px'}} className="col-20">
                        <SotreSearchBlock AllParams={AllParams} setAllParams={setAllParams} setSelectedCat={setSelectedCat} setSelectedBrand={setSelectedBrand} />
                    </div>
                </div>
            </div>
        </div>
        } 
         {windowWidth <= 1000 &&
        <div className={`overlay ${mobileMenu2 ? 'open' : ''}`} onClick={() => setMobileMenu2(false)}>
            <div className={`mobile_menu ${mobileMenu2 ? 'open' : ''}`} onClick={e => e.stopPropagation()} style={{maxWidth: '300px', width: '100%'}}>
                <div className="mobile_menu_content">
                    <button className="close-btn" onClick={() => setMobileMenu2(false)} style={{position: 'absolute', right: '16px'}}><img src={img_pack.close_icon} alt="close_icon"/></button>
                    <div style={{padding: '10px 16px'}} className="col-20">
                        <FilterSearchBlock rangePrice={rangePrice} setRangePrice={setRangePrice} sortingProducts={sortingProducts} query={query} setQuery={setQuery} selectedCat={selectedCat} setSelectedCat={setSelectedCat} selectedBrand={selectedBrand} setSelectedBrand={setSelectedBrand} maxPrice={maxPrice}/>
                    </div>
                </div>
            </div>
        </div>
        } 
        <section className="block-wls"> 
            <div className="container">
                <div className="block-wls-layout">
                    {windowWidth > 1000 &&
                        <StoreLsidebar rangePrice={rangePrice} setRangePrice={setRangePrice} sortingProducts={sortingProducts} query={query} setQuery={setQuery} selectedCat={selectedCat} setSelectedCat={setSelectedCat} selectedBrand={selectedBrand} setSelectedBrand={setSelectedBrand} AllParams={AllParams} setAllParams={setAllParams} maxPrice={maxPrice}/>
                    }
                    <div className="block-wls-content">
                        <div className="col">
                            <div className="products-title storePage">
                                {windowWidth > 1000 ?
                                <div className="h-12-400-i gr7">Показано {productsAll?.length} товаров из {totalProducts}</div>
                                :
                                <div className="row-15-a">
                                    <button className="row-10-a base-btn-wh" onClick={() => setMobileMenu1(true)}>
                                        <div className="h-14-400-j black">Подбор</div>
                                    </button>
                                    <button className="row-10-a base-btn-wh" onClick={() => setMobileMenu2(true)}>
                                        <div className="h-14-400-j black">Фильтр</div>
                                    </button>
                                </div>
                                }
                                <div className="row-20-a">
                                    <div style={{width: '200px'}}>
                                        <CustomSelect allOption={[{id:1, slug: 'price_asc', name: 'По возрастанию цены'},{id:2, slug: 'price_desc', name: 'По убыванию цены'},{id:3, slug: 'created_desc', name: 'По новизне'}]} ActiveItems={activeSort} setActiveItems={(e) => setActiveSort(e)} single={true} enclosure={false} placeholder={'Поиск по товарам...'}/>
                                    </div>
                                    {windowWidth > 1000 &&
                                    <div className="dual-btn">
                                        <button className={`h-14-400-j ${!viewSelect ? 'active white' : ''}`} onClick={() => setViewSelect(false)}><img_pack.menu_component_icon className={`dual-btn-img ${!viewSelect ? 'active' : ''}`} /></button>
                                        <button className={`h-14-400-j ${viewSelect ? 'active white' : ''}`} onClick={() => setViewSelect(true)}><img_pack.grid_component_icon className={`dual-btn-img ${viewSelect ? 'active' : ''}`} /></button>
                                    </div>}
                                </div>
                            </div>
                            {filtersUsed?.filter(item => item.value !== '').length > 0 &&
                            <div className="store_filters">
                                <div className="h-14-400-i">Фильтры:</div>
                                {(filtersUsed?.filter(item => item.value !== ''))?.map((el) => (
                                    <div key={el.name} className="ba-filter h-12-500-i gr7">{el.name}: {el.value} <button className="close_ba_btn" onClick={() => filterDel(el.slug)}/></div>
                                ))}
                            </div>}
                        </div>

                        {(storeLoading) && <div className="loader-container"><div className="loader"></div></div>}
                        <div className={`${viewSelect ? 'products-block' : 'products-block_line'}`}>
                            {!storeLoading && productsAll?.map((el) => (
                                viewSelect ?
                                <ProductExample key={el.id} res={el} className={'productExample-storeMob'}></ProductExample>
                                : <ProductExample_line key={el.id} res={el}></ProductExample_line>
                            ))}
                        </div>

                        {totalPages > 1 &&
                        <div className="t_foot">
                            <button onClick={() => {setCurrentPage( ((currentPage - 1) >= 1) ? (currentPage - 1) : (currentPage) ); window.scrollTo(0, 0);}}>
                                <span className="prev_arrow_svg"/>
                                <span className="h-14-600-i">Предыдущее</span>
                            </button>
                            <Pagination totalPages={totalPages} pagesToShow={1} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                            <button onClick={() => {setCurrentPage( ((currentPage + 1) <= totalPages) ? (currentPage + 1) : (currentPage) ); window.scrollTo(0, 0);}}>
                                <span className="h-14-600-i">Следующие</span>
                                <span className="next_arrow_svg"/>
                            </button>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </section>
       </>
    )
}

export default StorePage;