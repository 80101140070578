import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import React, { useContext, useEffect, useState } from "react"
import img_pack from "../img/img";
import CustomDpd from "./components/Custom_dp_link";
import GetStore from "../API/GetStore";
import { useFetching } from "../hooks/UseFetching";
import { useLocation, useParams } from "react-router";
import globalFunction, { egoda_url } from "../utils/consts";
import Product_tabs from "./components/Product_tabs";
import PlayerComponent from "./components/PlayerComponents";
import GetCart from "../API/GetCart";
import ProductCounter from "./components/ProductCounter";
import { Context } from "../App";
import LazyImage from "./components/LazyImage";
import EditorAdd from "./components/EditorAdd";

const ProductsPage = () => {

    const location = useLocation()
    const [count, setCount] = useState(1);
    const [product, setProduct] = useState('');

    const {windowWidth,AddCart, breadCrumbs, setBreadCrumbs} = useContext(Context)
    const {slugParam, catParam} = useParams() //productsPage


    function AddToCart(id, quantity, override) {
        const data = new FormData();
        data.append("product_id", id || '');
        data.append("quantity", quantity || '');
        data.append("override", override || false);

        // console.log('asd', id, quantity, override)
    
        AddCart(data)
    };

    // Получение товара
    const [GetProduct] = useFetching( async (slug) => {
        const res = await GetStore.GetProduct(slug)
        // console.log('GetProduct complete:' , res.data)
        setProduct(res.data.data)
        setBreadCrumbs({productName: res.data.data.name, catName: res.data.data.category_name})
        return res;
    })

    useEffect(() => {
        if(slugParam) {
            GetProduct(slugParam)
        }
    },[location.pathname])


    return (
       <>
        <section className="block-base"> 
            <div className="container"> 
                <div className="block-base-content">
                    <div className="product-prv-row">
                        <div>
                            {product?.gallery_images?.length > 0 ?
                                <div className="splide-block">
                                    <Splide hasTrack={ false } options={{
                                        rewind: true,
                                        gap: '1rem',
                                        perPage: 1,
                                        pagination: true,
                                    }}>
                                        <SplideTrack>
                                            {product?.video_url && 
                                                <SplideSlide >
                                                    <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                                        <PlayerComponent videoUrl ={`${egoda_url}${product.video_url}`}></PlayerComponent>
                                                    </div>
                                                </SplideSlide>
                                            }
                                            {product?.image_url &&
                                                <SplideSlide >
                                                    <LazyImage src={`${egoda_url}${product.image_url}`} alt="img" />
                                                </SplideSlide>
                                            }
                                            {product?.gallery_images?.map((el) => (
                                                <SplideSlide key={el.id}>
                                                    <LazyImage src={`${egoda_url}${el.image_url}`} alt="img" />
                                                </SplideSlide>
                                            ))}
                                        </SplideTrack>
                                        <div className="splide__arrows">
                                            <button className="splide__arrow splide__arrow--prev"></button>
                                            <button className="splide__arrow splide__arrow--next"></button>
                                        </div>
                                    </Splide>
                                </div>
                                :
                                (product?.image_url ?
                                <LazyImage src={`${egoda_url}${product.image_url}`} alt="img" />
                                : <LazyImage style={{objectFit: 'contain'}} src={`${img_pack.product_example}`} alt="img" />)
                                
                            }
                        </div>
                        {product?.product_atr?.length > 0 &&
                        <div className="details_block">
                            <div className="col-15">
                                <div className="h-20-400-i">Характеристики</div>
                                <span style={{borderBottom: '1px solid #D0D5DD'}} />
                            </div>
                            <div className="col">
                                {product?.product_atr?.map((el) => (
                                <div key={el.id} className="product-detail-ex">
                                    <div className="h-14-700-i">{el.name}:</div>
                                    <div className="h-14-400-i">{el.value}</div>
                                </div>
                                ))
                                }
                            </div>
                        </div>}
                        <div>
                            <div className="col-5">
                                {product?.item_number &&
                                <div className="row-5-a"><span className="h-12-600-i gr5">Артикул:</span><span className="h-12-400-i gr5">{product?.item_number}</span></div>
                                }
                                {product?.quantity &&
                                <div className="row-5-a"><span className="h-12-600-i gr5">В наличии:</span><span className="h-12-400-i gr5">{product?.quantity} шт.</span></div>
                                }
                            </div>
                            {product?.short_desc?.blocks?.length > 0 &&
                            <div className="h-14-400-i">
                                <EditorAdd blocks={product.short_desc?.blocks}></EditorAdd>
                            </div>
                            }
                            <div className="row-10">
                                <div className="h-36-600-i">{globalFunction.withSpaces(globalFunction.split(product?.price)[0])} ₽</div>
                                <div className="h-12-400-i gr5" style={{alignSelf: 'flex-end', padding: '5px 0'}}>{count} шт. * {globalFunction.withSpaces(globalFunction.split(product?.price)[0])} ₽ = {globalFunction.withSpaces(globalFunction.split(product?.price * count)[0])} ₽   </div>
                            </div>
                            <div className="product-row-cwbtns">
                                <ProductCounter onChange={(e) => setCount(e) } initialCount={count} />
                                <button onClick={() => AddToCart(product.id, count)} className="product_cart_btn h-14-400-i">В корзину</button>
                            </div>
                        </div>
                    </div>

                    <Product_tabs fulldesc={product?.desc} productTabs={product?.product_tab}></Product_tabs>

                </div>
            </div>
        </section>
       </>
    )
}

export default ProductsPage;