import React, { useContext, useEffect, useRef, useState } from "react"

const AdminHelpPage = () => {

 
    return (
        <div className="col-20">
            <div className="h-16-500-i">Помощь</div>
            <div className="h-14-400-i">Если у вас по каким-то причинам не запускаются видео в плеере, вы можете найти их в Яндекс Диске: <a className="underline" href="https://disk.yandex.ru/d/YeRzuYX81KpiGQ">https://disk.yandex.ru/d/YeRzuYX81KpiGQ</a></div>
        
            <div className="h-16-500-i">Общая сводка</div>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/yUWrFc7BAiM?si=tBWo71s3nam-YYlS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

            <div className="h-16-500-i">Магазин</div>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/SB_YY45h1FQ?si=L-nw8sIgaDKU_QLS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

            <div className="h-16-500-i">Обратная связь</div>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/OuE1sSyDQCw?si=qQ4gJqI2R4_eKjWI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

            <div className="h-16-500-i">Медиафайлы</div>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/b3-c4k3DASw?si=leYXixmSy9dwJi6l" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            
            <div className="h-16-500-i">Пользователи</div>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/-mbIimtDEMk?si=ib-oyWHhO8knmdy7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

            <div className="h-16-500-i">Настройки</div>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/JVZNrcS3WcM?si=4D6fPBT2OT0KCuS-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>


        </div>
    )
}

export default AdminHelpPage;