import React, { useContext, useEffect, useState } from "react"
import img_pack from "../img/img";
import BaseLsidebar from "./components/BaseLsidebar";
import { Context } from "../App";

const FaqPage = () => {

    const {windowWidth} = useContext(Context)

    return (
       <>
        <section className="block-wls"> 
            <div className="container">
                <div className="block-wls-layout">
                    {windowWidth > 1200 &&
                        <BaseLsidebar />
                    }
                    <div className="block-wls-content">
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Почему стоит оформлять заказ на сайте?</div>
                            <div className="col">
                                <div className="h-14-400-i c-2">1. Оформление заказа на сайте поможет избежать ошибок и недоразумений, так как вы сами заполняете информацию о заказе (ФИО, номер телефона, адрес доставки). Каждый заказ, оформленный через сайт, автоматически регистрируется на сервере. После этого вам на электронную почту будет отправлен номер заказа, по которому можно в любое время узнать статус выполнения, а также поступит СМС-уведомление.</div>
                                <div className="h-14-400-i c-2">2. Очереди не нравятся никому, и даже в интернет-магазинах они могут возникать. Мы получаем большое количество звонков, особенно в сезон, поэтому иногда дозвониться быстро не получается из-за загруженности телефонов и операторов. На сайте можно найти ответы на большинство вопросов, и обычно оформление заказа не требует участия оператора. Актуальные остатки и цены на товары всегда доступны, поэтому можно смело оформлять заказ прямо на сайте. После этого оператор свяжется с вами в кратчайшие сроки для согласования удобного времени доставки. Мы призываем экономить свое и чужое время!</div>
                            </div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Какая цена на шины или диски?</div>
                            <div className="h-14-400-i c-2">Узнать цену очень просто — она указана на нашем сайте. Просто выберите нужные вам шины или диски через подбор на главной странице, и на странице товара вы сразу увидите цену. Также вы можете сразу оформить заказ на сайте, что гарантирует получение товара по указанной цене.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Есть ли в наличии эти шины?</div>
                            <div className="h-14-400-i c-2">Здесь все просто: информация о наличии шин и дисков указана на странице товара. Если написано, что товар есть в наличии, значит, он действительно есть! Данные о наличии и ценах обновляются несколько раз в день, поэтому при оформлении заказа вы видите актуальное количество и цены.</div>
                            <div className="h-14-400-i c-2">Конечно, теоретически может возникнуть ситуация, когда, например, было 4 шины или диска, и их купили в 14:00, а вы оформили заказ на этот же комплект в 14:10. Однако такие случаи крайне редки, так как популярные модели всегда есть на складе в достаточном количестве, и вы можете смело оформлять заказ на сайте.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Качество шин, произведенных в России</div>
                            <div className="h-14-400-i c-2">В России работают многие крупные и известные производители шин. У большинства крупных компаний есть заводы в разных странах, включая Китай, Польшу, Филиппины и Словению, но почему-то к шинам российского производства часто относятся предвзято.</div>
                            <div className="h-14-400-i c-2">Некоторые считают, что продукция, произведенная в России или странах третьего мира, будет низкокачественной. Однако это далеко не так. Для выпуска шин производитель устанавливает соответствующее оборудование и тщательно контролирует качество сырья и всех этапов производства. Шины, произведенные в России, экспортируются на рынки других стран, включая Центральную Европу, и ни одна компания не будет рисковать своей репутацией.</div>
                            <div className="h-14-400-i c-2">Предвзятое отношение к российскому производству также связано с мифами о качестве работы нашего персонала. Но современные заводы автоматизированы, и руками выполняется лишь несколько операций: закладка сырья, визуальная проверка и погрузка. Поэтому вопрос о непрофессионализме российского персонала не имеет оснований.</div>
                            <div className="h-14-400-i c-2">Мы, АССАВТО, обладаем большим опытом в сфере продаж шин и не видим разницы в качестве продукции в зависимости от страны производства. Все убеждения о зависимости качества от места производства — это предрассудки.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Хочу купить одно колесо, а не четыре. Продадите?</div>
                            <div className="h-14-400-i c-2">Чтобы уточнить возможность покупки одного колеса, свяжитесь с нами по телефону: +7 (351) 776-49-91 или оформите заказ на сайте, и менеджер свяжется с вами.</div>
                            <div className="h-14-400-i c-2">P.S.: Помните, что колеса, установленные на одной оси автомобиля, должны быть идентичными (по марке, модели, типоразмеру, износу и т.п.). Разный износ колес может быть незаметен на первый взгляд, но при резком торможении это может привести к негативным последствиям. Поэтому, если одно колесо пришло в негодность, мы рекомендуем заменять сразу оба колеса на одной оси.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Есть ли у вас шиномонтаж для купленных шин?</div>
                            <div className="h-14-400-i c-2">К сожалению, на данный момент у нас нет своего шиномонтажа. Вы можете воспользоваться услугами шиномонтажа у наших ключевых партнеров: Quattro и Оранжевый шиномонтаж </div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Какую скидку я получу, если сделаю заказ на комплект шин или дисков?</div>
                            <div className="h-14-400-i c-2">Мы придерживаемся политики честного ценообразования и не накручиваем цены, чтобы потом делать скидки. Мы всегда предлагаем своим клиентам максимально низкие цены, чтобы не вводить в заблуждение и приятно радовать стоимостью.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Возврат или обмен шин или дисков?</div>
                            <div className="h-14-400-i c-2">Возврат или обмен шин или дисков возможен в течение 14 дней, при условии отсутствия следов шиномонтажных работ.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Мне нужны колеса, но я не знаю размер</div>
                            <div className="h-14-400-i c-2">На главной странице нашего сайта есть три варианта поиска товара: по размеру шин, размеру дисков и по вашему автомобилю. Выберите марку, модель, год выпуска и объем двигателя вашего авто, и наш фильтр предложит все возможные варианты размеров шин и дисков.</div>
                            <div className="h-14-400-i c-2">Если возникнут трудности, свяжитесь с нами по телефону: +7 (351) 776-49-91, напишите в чате или запросите обратный звонок на сайте. Мы обязательно подберем нужные вам колеса.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">Как узнать страну и/или год выпуска шин?</div>
                            <div className="h-14-400-i c-2">Оформите заказ, и при согласовании заказа менеджер уточнит для вас информацию о стране и годе выпуска шин.</div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
       </>
    )
}

export default FaqPage;