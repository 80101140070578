import React, { useContext, useEffect, useState } from "react"
import img_pack from "../../img/img";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { Context } from "../../App";
import { CartOrderPageRoot, CartPageRoot, Email_verify_Root, ExchangeAndRefundRoot, FaqPageRoot, Login_Root, OrderPageRoot, Page404_Root, Password_new_Root, Password_recovery_Root, PaymentAndDeliveryRoot, PrivacyPolicyRoot, Registration_Root, StorePageRoot } from "../../utils/consts";

const BreadCrumb = () => {

    const {breadCrumbs, setBreadCrumbs} = useContext(Context)

    const breadcrumbMap = {
        [Login_Root.toLowerCase()]: 'Авторизация',
        [Registration_Root.toLowerCase()]: 'Регистрация',
        [Password_recovery_Root.toLowerCase()]: 'Восстановление пароля',
        [Password_new_Root.toLowerCase()]: 'Создание нового пароля',
        [Email_verify_Root.toLowerCase()]: 'Подтверждение почты',
        [FaqPageRoot.toLowerCase()]: 'Вопросы и ответы',
        [PaymentAndDeliveryRoot.toLowerCase()]: 'Оплата и доставка',
        [ExchangeAndRefundRoot.toLowerCase()]: 'Обмен и возврат',
        [PrivacyPolicyRoot.toLowerCase()]: 'Политика конфиденциальности',
        [StorePageRoot.toLowerCase()]: 'Магазин',
        [CartPageRoot.toLowerCase()]: 'Корзина',
        [CartOrderPageRoot.toLowerCase()]: 'Оформление заказа',
        [Page404_Root.toLowerCase()]: 'Ошибка 404',
    };

    function splitPathname(pathname) {
        // Удаляем первый слэш, если он есть
        if (pathname.startsWith('/')) {
            pathname = pathname.slice(1);
        }
        // Разделяем строку по слэшам
        const parts = pathname.split('/');
        const fullPaths = [];
        // Формируем массив полных путей
        let currentPath = '';
        parts.forEach(part => {
            currentPath += (currentPath ? '/' : '') + part;
            fullPaths.push(currentPath);
        });
        // Возвращаем объект с полными путями и последним полным путем
        return fullPaths;
    }

    const [navArr, setNavArr] = useState([]);

    const navigate = useNavigate()
    const location = useLocation()

    const [pa, setPa] = useState('');

    useEffect(() => {
        setNavArr(splitPathname(location.pathname))
        if (/^\/shop\/([^\/]+)\/([^\/]+)$/.test(location.pathname)) {
            setPa('isProduct');
        } else if (/^\/order\/([^\/]+)$/.test(location.pathname)) {
            setPa('isOrder');
        } else {
            setPa('');
        }
    },[location.pathname])

    return (
    <>
    {(location.pathname !== '/') &&
    <div className="header-page-nav">
        <div className="container">
            <div className="header-page-nav-content">
                <div className="col-5">
                    <div className="row-10-a h-12-400-i">
                        {pa === 'isProduct' ?
                        <>
                            <Link className="gr8 h-12-400-i" to='/'>Главная</Link>
                            <span className="h-12-400-i gr8">/</span>
                            <Link className="gr8 h-12-400-i" to='/shop'>Магазин</Link>
                            <span className="h-12-400-i gr8">/</span>
                            <span className='h-12-400-i'>{breadCrumbs.catName}</span>
                            <span className="h-12-400-i gr8">/</span>
                            <span className='h-12-700-i'>{breadCrumbs.productName}</span>

                        </>
                        : pa === 'isOrder' ?
                            <>
                                <Link className="gr8 h-12-400-i" to='/'>Главная</Link>
                                <span className="h-12-400-i gr8">/</span>
                                <span className={'h-12-700-i'}>Заказ оформлен</span>
                            </>
                            :
                            <>
                                <Link className="gr8 h-12-400-i" to='/'>Главная</Link>
                                {navArr && navArr.map((el) => (
                                    <React.Fragment key={el}>
                                        <span className="h-12-400-i gr8">/</span>
                                        <Link className={`${navArr[navArr.length-1] === el ? 'h-12-700-i' : 'h-12-400-i'}`} to={`/${el}`}>
                                            { breadcrumbMap[`/${el.toLowerCase()}`] }
                                        </Link>
                                    </React.Fragment>
                                ))}
                            </>
                        } 
                    </div>
                    <div className="row-20-a">
                        <button className="gr8 h-12-400-i" onClick={() => navigate(-1)}><img style={{minWidth: 'max-content'}} src={img_pack.back_icon} alt="back_icon" /></button>
                        <div className="h-36-400-j gr8">
                            {pa === 'isProduct' 
                            ? breadCrumbs?.productName  || breadcrumbMap[location.pathname.toLowerCase()]
                            : (pa === 'isOrder' ? 'Заказ оформлен'
                            : breadcrumbMap[location.pathname.toLowerCase()])
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    }
    </>
    )
}

export default BreadCrumb;