import { useEffect, useState } from "react";
import { useFetching } from "./UseFetching";
import GetConfigurator from "../API/GetConfigurator";

export const useByCarConfigurator = ({selected1}) => {

    const [dataByCarTires, setDataByCarTires] = useState({
        stamp: [],
        model: [],
        year: [],
        engine: []
    })
    const [dataByCarDiscs, setDataByCarDiscs] = useState({
        stamp: [],
        model: [],
        year: [],
        engine: []
    })

    const [allTiresMarks, setAllTiresMarks] = useState([])
    const [allTiresModels, setAllTiresModels] = useState([])
    const [allTiresYear, setAllTiresYear] = useState([])
    const [allTiresTrim, setAllTiresTrim] = useState([])

    const [allDisksMarks, setAllDisksMarks] = useState([])
    const [allDisksModels, setAllDisksModels] = useState([])
    const [allDisksYear, setAllDisksYear] = useState([])
    const [allDisksTrim, setAllDisksTrim] = useState([])

    // Получение всех марок
    const [GetConfiguratorBrands, error1] = useFetching( async (data) => {
        const res = await GetConfigurator.GetConfiguratorBrands(data)
        // console.log('GetConfiguratorBrands complete:' , res.data)
        if(selected1) {// диски
            setAllDisksMarks(res.data.result.map((el,i) => ({id: i, slug: el, name: el})));
        } else {// шины
            setAllTiresMarks(res.data.result.map((el,i) => ({id: i, slug: el, name: el})));
        }
    })
    // Получение всех моделей
    const [GetConfiguratorModels, error2] = useFetching( async (data) => {
        const res = await GetConfigurator.GetConfiguratorModels(data)
        // console.log('GetConfiguratorModels complete:' , res.data)
        if(selected1) {// диски
            setAllDisksModels(res.data.result.map((el,i) => ({id: i, slug: el, name: el})));
        } else {// шины
            setAllTiresModels(res.data.result.map((el,i) => ({id: i, slug: el, name: el})));
        }
    })
    // Получение всех годов
    const [GetConfiguratorYear, error3] = useFetching( async (data) => {
        const res = await GetConfigurator.GetConfiguratorYear(data)
        // console.log('GetConfiguratorYear complete:' , res.data)
        if(selected1) {// диски
            setAllDisksYear(res.data.result.map((el,i) => (
                {id: i, slug: el, name: el}
            )));
        } else {// шины
            setAllTiresYear(res.data.result.map((el,i) => (
                {id: i, slug: el, name: el}
            )));
        }
    })
    // Получение комплектации
    const [GetConfiguratorTrim, error4] = useFetching( async (data) => {
        const res = await GetConfigurator.GetConfiguratorTrim(data)
        // console.log('GetConfiguratorTrim complete:' , res.data)
        if(selected1) {// диски
            setAllDisksTrim(res.data.result.map((el,i) => ({id: i, slug: el, name: el})));
        } else {// шины
            setAllTiresTrim(res.data.result.map((el,i) => ({id: i, slug: el, name: el})));
        }
    })
    // Обработка ошибок
    useEffect(() => {
        if(error1) {setAllDisksMarks([]);setAllTiresMarks([])}
        if(error2) {setAllDisksModels([]);setAllTiresModels([])}
        if(error3) {setAllDisksYear([]);setAllTiresYear([])}
        if(error4) {setAllDisksTrim([]);setAllTiresTrim([])}
    },[error1,error2,error3,error4])
    const [prevdataByCarDiscs, setPrevdataByCarDiscs] = useState(dataByCarDiscs);
    const [prevDataByCarTires, setPrevDataByCarTires] = useState(dataByCarTires);
    // reset dataByCarDiscs
    useEffect(() => {
        if (prevdataByCarDiscs.stamp !== dataByCarDiscs.stamp) {
            setDataByCarDiscs(prevState => ({...prevState, model: []}))
        }
        if (prevdataByCarDiscs.model !== dataByCarDiscs.model) {
            setDataByCarDiscs(prevState => ({...prevState, year: []}))
        }
        if (prevdataByCarDiscs.year !== dataByCarDiscs.year) {
            setDataByCarDiscs(prevState => ({...prevState, engine: []}))
        }
        setPrevdataByCarDiscs(dataByCarDiscs); // обновляем предыдущее состояние
    }, [dataByCarDiscs]);
    // reset dataByCarTires
    useEffect(() => {
        if (prevDataByCarTires.stamp !== dataByCarTires.stamp) {
            setDataByCarTires(prevState => ({...prevState, model: []}))
        }
        if (prevDataByCarTires.model !== dataByCarTires.model) {
            setDataByCarTires(prevState => ({...prevState, year: [] }))
        }
        if (prevDataByCarTires.year !== dataByCarTires.year) {
            setDataByCarTires(prevState => ({...prevState, engine: []}))
        }
        setPrevDataByCarTires(dataByCarTires); // обновляем предыдущее состояние
    }, [dataByCarTires]);
    useEffect(() => {
        const data = new FormData();
        data.append("good_type", selected1 ? 'disk' : 'tire');
        GetConfiguratorBrands(data)
        setDataByCarTires({ stamp: [], model: [], year: [], engine: [] });
        setDataByCarDiscs({ stamp: [], model: [], year: [], engine: [] });
    },[selected1])
    useEffect(() => {
        if(dataByCarTires.stamp.length > 0) {
            const data = new FormData();
            data.append("good_type", selected1 ? 'disk' : 'tire');
            data.append("brand", dataByCarTires.stamp[0].value || '');
            GetConfiguratorModels(data)
        }
    },[dataByCarTires.stamp])
    useEffect(() => {
        if(dataByCarTires.model.length > 0) {
            const data = new FormData();
            data.append("good_type", selected1 ? 'disk' : 'tire');
            data.append("brand", dataByCarTires.stamp[0]?.value || '');
            data.append("model", dataByCarTires.model[0]?.value || '');
            GetConfiguratorYear(data)
        }
    },[dataByCarTires.model])
    useEffect(() => {
        if(dataByCarTires.year.length > 0) {
            const data = new FormData();
            data.append("good_type", selected1 ? 'disk' : 'tire');
            data.append("brand", dataByCarTires.stamp[0]?.value || '');
            data.append("model", dataByCarTires.model[0]?.value || '');
            data.append("years", dataByCarTires.year[0]?.value || '');
            GetConfiguratorTrim(data)
        }
    },[dataByCarTires.year])

    useEffect(() => {
        if(dataByCarDiscs.stamp.length > 0) {
            const data = new FormData();
            data.append("good_type", selected1 ? 'disk' : 'tire');
            data.append("brand", dataByCarDiscs.stamp[0].value || '');
            GetConfiguratorModels(data)
        }
    },[dataByCarDiscs.stamp])
    useEffect(() => {
        if(dataByCarDiscs.model.length > 0) {
            const data = new FormData();
            data.append("good_type", selected1 ? 'disk' : 'tire');
            data.append("brand", dataByCarDiscs.stamp[0]?.value || '');
            data.append("model", dataByCarDiscs.model[0]?.value || '');
            GetConfiguratorYear(data)
        }
    },[dataByCarDiscs.model])
    useEffect(() => {
        if(dataByCarDiscs.year.length > 0) {
            const data = new FormData();
            data.append("good_type", selected1 ? 'disk' : 'tire');
            data.append("brand", dataByCarDiscs.stamp[0]?.value || '');
            data.append("model", dataByCarDiscs.model[0]?.value || '');
            data.append("years", dataByCarDiscs.year[0]?.value || '');
            GetConfiguratorTrim(data)
        }
    },[dataByCarDiscs.year])
    
    return {allTiresMarks,allTiresModels,allTiresYear,allTiresTrim,allDisksMarks,allDisksModels,allDisksYear,allDisksTrim,dataByCarTires, setDataByCarTires, dataByCarDiscs, setDataByCarDiscs}
}