import React, { useContext, useEffect, useRef, useState } from "react"
import ImportTabs from "./ImportTabs";

const AdminStoreImportPage = () => {

    return (
        <div className="col-20">
            <div className="h-16-500-i" style={{padding: '10px'}}>Импорт </div>
            <div className="h-14-400-i" style={{padding: '10px'}}>Выберите способ импорта</div>
            <ImportTabs />
 
        </div>
    )
}

export default AdminStoreImportPage;