import axios from "axios"
import { egoda_url } from "../utils/consts";

export default class GetConfigurator {
    // Получить марки
    static async GetConfiguratorBrands(data) {
        const response = await axios.post(`${egoda_url}/car_configurator/get_brands/`, data,
        {
            withCredentials: true
        });

        return response;
    }
    // Получить модели
    static async GetConfiguratorModels(data) {
        const response = await axios.post(`${egoda_url}/car_configurator/get_models/`, data,
        {
            withCredentials: true
        });

        return response;
    }
    // Получить года
    static async GetConfiguratorYear(data) {
        const response = await axios.post(`${egoda_url}/car_configurator/get_year/`, data,
        {
            withCredentials: true
        });

        return response;
    }
    // Получить комплектации
    static async GetConfiguratorTrim(data) {
        const response = await axios.post(`${egoda_url}/car_configurator/get_trim/`, data,
        {
            withCredentials: true
        });

        return response;
    }
    // Получить варианты
    static async GetConfiguratorItems(data) {
        const response = await axios.post(`${egoda_url}/car_configurator/get_items/`, data,
        {
            withCredentials: true
        });

        return response;
    }
// Параметры
    // Получить атрибуты для шин
    static async GetConfiguratorTireAttr(data) {
        const response = await axios.post(`${egoda_url}/shop/attr/get_tire/`, data,
        {
            withCredentials: true
        });

        return response;
    }
    // Получить атрибуты для диска
    static async GetConfiguratorDiskAttr(data) {
        const response = await axios.post(`${egoda_url}/shop/attr/get_disk/`, data,
        {
            withCredentials: true
        });

        return response;
    }
    // Получить подкатегории для параметров
    static async GetSubcategoryAttrs(data) {
        const response = await axios.post(`${egoda_url}/shop/attr/get_subcategory_attrs/?`, data,
        {
            withCredentials: true
        });

        return response;
    }
}