import React, { useContext, useEffect, useState } from "react"
import img_pack from "../img/img";
import BaseLsidebar from "./components/BaseLsidebar";
import { Context } from "../App";

const PrivacyPolicyPage = () => {

    const {windowWidth} = useContext(Context)

    return (
        <section className="block-wls"> 
            <div className="container">
                <div className="block-wls-layout">
                    {windowWidth > 1200 &&
                        <BaseLsidebar />
                    }
                    <div className="block-wls-content">
                        <div className="col-20">
                            <div className="h-28-400-j c-2">1. Общие положения</div>
                            <div className="col-10">
                                <div className="h-14-400-i c-2">1.1. Настоящая Политика в отношении обработки персональных данных (далее – Политика) определяет политику ООО «АРИСПЕЦСИТИ74» (далее – Компания) в отношении обработки и обеспечения безопасности персональных данных.</div>
                                <div className="h-14-400-i c-2">1.2. Политика разработана в соответствии с законодательством Российской Федерации в области персональных данных.</div>
                                <div className="h-14-400-i c-2">1.3. Целью настоящей политики является установление основных принципов и подходов к обработке и обеспечению защиты персональных данных в Компании, являющейся оператором персональных данных.</div>
                                <div className="h-14-400-i c-2">1.4. Действие Политики распространяется на все процессы Компании, связанные с обработкой персональных данных.</div>
                                <div className="h-14-400-i c-2">1.5. Политика обязательна для ознакомления и исполнения всеми лицами, допущенными к обработке персональных данных.</div>
                                <div className="h-14-400-i c-2">1.6. Текущая редакция Политики размещается на сайте Компании в общем доступе и вступает в силу с момента размещения.</div>
                            </div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">2. Термины и определения</div>
                            <div className="h-14-400-i c-2">Персональные данные - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).</div>
                            <div className="h-14-400-i c-2">Обработка персональных данных - любое действие (операция) или совокупность действий (операций), совершаемых с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</div>
                            <div className="h-14-400-i c-2">Автоматизированная обработка персональных данных - обработка персональных данных с помощью средств вычислительной техники.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">3. Категории субъектов, персональные данные которых обрабатываются Компанией</div>
                            <div className="h-14-400-i c-2">3.1. Компанией осуществляется обработка полученных в установленном законом порядке:</div>
                            <div className="col">
                                <div className="h-14-400-i c-2">- персональных данных, принадлежащих работникам Компании;</div>
                                <div className="h-14-400-i c-2">- персональных данных клиентов Компании (физических лиц), полученным в связи с заключением договора, стороной которого является субъект персональных данных; и оформлении заявки на обратную связь на сайте Компании, размещенном на домене <a href="http://ass74.ru/" target="_blank" rel="noopener noreferrer">http://ass74.ru/</a>.</div>
                                <div className="h-14-400-i c-2">- персональных данных руководителей юридических лиц (а именно: фамилия, имя, отчество), являющихся клиентами Компании и полученных в связи с заключением договора, поставщиков и других юридических лиц, имеющих договорные отношения с Компанией.</div>
                            </div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">4. Основные принципы обработки персональных данных</div>
                            <div className="h-14-400-i c-2">4.1. Обработка персональных данных Компанией осуществляется на основе принципов:</div>
                            <div className="col">
                                <div className="h-14-400-i c-2">- законности целей и способов обработки персональных данных;</div>
                                <div className="h-14-400-i c-2">- добросовестности Компании, как оператора персональных данных, что достигается путем выполнения требований законодательства Российской Федерации в отношении обработки персональных данных;</div>
                                <div className="h-14-400-i c-2">- соответствия состава и объема обрабатываемых персональных данных, а также способов обработки персональных данных заявленным целям обработки;</div>
                                <div className="h-14-400-i c-2">- точности и достаточности, а в необходимых случаях и актуальности персональных данных по отношению к заявленным целям их обработки;</div>
                                <div className="h-14-400-i c-2">- хранения персональных данных не дольше, чем этого требуют цели обработки, если срок хранения персональных данных не установлен законом или договором;</div>
                                <div className="h-14-400-i c-2">- недопустимости объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, несовместимых между собой.</div>
                            </div>
                            <div className="h-14-400-i c-2">4.2. Работники Компании, допущенные к обработке персональных данных, обязаны:</div>
                            <div className="col">
                                <div className="h-14-400-i c-2">- знать и выполнять положения законодательства Российской Федерации в области персональных данных, настоящей Политики;</div>
                                <div className="h-14-400-i c-2">- обрабатывать персональные данные только в рамках выполнения своих должностных обязанностей;</div>
                                <div className="h-14-400-i c-2">- не разглашать персональные данные, обрабатываемые в Компании;</div>
                                <div className="h-14-400-i c-2">- сообщать руководству Компании о действиях других лиц, которые могут привести к нарушению положений настоящей Политики.</div>
                            </div>
                            <div className="h-14-400-i c-2">4.3. Безопасность персональных данных в Компании обеспечивается выполнением согласованных мероприятий, направленных на предотвращение (нейтрализацию) и устранение угроз безопасности персональных данных, минимизацию возможного ущерба, а также мероприятий по восстановлению данных и работы информационных систем персональных данных в случае реализации угроз.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">5. Организация обработки персональных данных</div>
                            <div className="h-14-400-i c-2">5.1. Компания осуществляет обработку персональных данных с использованием средств автоматизации и без использования средств автоматизации.</div>
                            <div className="h-14-400-i c-2">5.2. Персональные данные не раскрываются третьим лицам и не распространяются иным образом без согласия субъекта персональных данных, если иное не предусмотрено законодательством Российской Федерации.</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">6. Права субъекта персональных данных</div>
                            <div className="h-14-400-i c-2">6.1. Субъект персональных данных, если иное не предусмотрено требованиями законодательства Российской Федерации, имеет право на получение, в том числе, следующей информации, касающейся обработки его персональных данных:</div>
                            <div className="col">
                                <div className="h-14-400-i c-2">- подтверждение факта обработки персональных данных Компанией;</div>
                                <div className="h-14-400-i c-2">- правовые основания и цели обработки персональных данных.</div>
                            </div>
                            <div className="h-14-400-i c-2">6.2. Субъект персональных данных имеет также иные права, установленные Федеральным законом «О персональных данных».</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">7. Обязанности Компании</div>
                            <div className="h-14-400-i c-2">7.1. Обработка персональных данных в Компании осуществляется с согласия субъекта персональных данных в порядке, установленном законодательством Российской Федерации.</div>
                            <div className="h-14-400-i c-2">7.2. В случаях, установленных законодательством Российской Федерации в области персональных данных, Компания обязана сообщить субъекту персональных данных либо его представителю, полномочия которого будут надлежащим образом оформлены, информацию, касающуюся обработки персональных данных субъекта.</div>
                            <div className="h-14-400-i c-2">7.3. Компания несет иные обязанности, установленные Федеральным законом «О персональных данных».</div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">8. Меры, направленные на обеспечение выполнения обязанности по защите персональных данных</div>
                            <div className="h-14-400-i c-2">8.1. В Компании принимаются следующие меры по защите персональных данных:</div>
                            <div className="col">
                                <div className="h-14-400-i c-2">- определяются угрозы безопасности персональных данных при их обработке в информационных системах персональных данных;</div>
                                <div className="h-14-400-i c-2">- применяются организационные и технические меры по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных, необходимые для выполнения требований к защите персональных данных, исполнение которых обеспечивает установленные Правительством Российской Федерации уровни защищенности персональных данных;</div>
                                <div className="h-14-400-i c-2">- проводится оценка эффективности принимаемых мер по обеспечению безопасности персональных данных до ввода в эксплуатацию информационной системы персональных данных;</div>
                                <div className="h-14-400-i c-2">- осуществляется принятие мер в целях исключения фактов несанкционированного доступа к персональным данным;</div>
                                <div className="h-14-400-i c-2">- проводится восстановление персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;</div>
                                <div className="h-14-400-i c-2">- устанавливаются правила доступа к персональным данным, обрабатываемым в информационной системе;</div>
                                <div className="h-14-400-i c-2">- работники Компании, непосредственно осуществляющие обработку и организующие защиту персональных данных, ознакамливаются с документами, определяющими политику Компании в отношении обработки персональных данных.</div>
                            </div>
                        </div>
                        <div className="col-20">
                            <div className="h-28-400-j c-2">9. Ответственность</div>
                            <div className="h-14-400-i c-2">9.1. Лица, виновные в нарушении норм, регулирующих получение, обработку и защиту обрабатываемых в Компании персональных данных, несут предусмотренную законодательством Российской Федерации ответственность.</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PrivacyPolicyPage;