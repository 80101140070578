import React, { useContext, useEffect, useState } from "react"
import ToggleBtn from "./toggleBtn";
import CustomSelect from "./Custom_select";
import img_pack from "../../img/img";
import { useLocation, useNavigate } from "react-router";
import { StorePageRoot } from "../../utils/consts";
import { useByCarConfigurator } from "../../hooks/UseByCarConfiguration";
import GetConfigurator from "../../API/GetConfigurator";
import { useFetching } from "../../hooks/UseFetching";
import { useByParamsConfigurator } from "../../hooks/UseByParamsConfiguration";

const SearchBlock = () => {

    const location = useLocation()
    const navigate = useNavigate()

    const [selected1, setSelected1] = useState(false)
    const [selected2, setSelected2] = useState(false)

    const {allTiresMarks,allTiresModels,allTiresYear,allTiresTrim,allDisksMarks,allDisksModels,allDisksYear,allDisksTrim,dataByCarTires, setDataByCarTires, dataByCarDiscs, setDataByCarDiscs} = useByCarConfigurator({selected1})
    const {allDisksType,tiresAttrLoading,disksAttrLoading,allSubcategoryAttrs,selectedSubcategoryAttrs,setSelectedSubcategoryAttrs,allTiresWidth,allTiresProfile,allTiresDiameter,allTiresSeasonality,allTiresType,allTiresManufacturer,allDisksWidth,allDisksDiameter,allDisksPCD,allDisksET,allDisksHub,allDisksManufacturer,dataByParamTires, setDataByParamTires, dataByParamDiscs, setDataByParamDiscs} = useByParamsConfigurator({selected1})
    
    // Получение всех вариантов (Автомобиль)
    const [GetConfiguratorItems] = useFetching( async (data, sel) => {
        const res = await GetConfigurator.GetConfiguratorItems(data)
        // console.log('GetConfiguratorItems complete:' , res.data)
        if(!sel) {
            navigate(`${StorePageRoot}`, { state: { searchInitialData: {selected1: false, selected2: true, itemsResult: res.data.result} } })
        } else {
            navigate(`${StorePageRoot}`, { state: { searchInitialData: {selected1: true, selected2: true, itemsResult: res.data.result} } })
        }
    })

    useEffect(() => {
        setSelected2(false)
    },[selected1])

    function GetByCarParams() {
        const data = new FormData();
        data.append("good_type", selected1 ? 'disk' : 'tire');
        data.append("brand", !selected1 ? dataByCarTires.stamp[0]?.value : dataByCarDiscs.stamp[0]?.value);
        data.append("model", !selected1 ? dataByCarTires.model[0]?.value : dataByCarDiscs.model[0]?.value);
        data.append("years", !selected1 ? dataByCarTires.year[0]?.value : dataByCarDiscs.year[0]?.value);
        data.append("trim", !selected1 ? dataByCarTires.engine[0]?.value : dataByCarDiscs.engine[0]?.value);
        GetConfiguratorItems(data, selected1)
    }

    function submit() {
        if(!selected1) {
            if(selected2) {
                if(dataByCarTires.engine.length > 0) {
                    GetByCarParams()
                }
            } else {
                navigate(`${StorePageRoot}`, { state: { searchInitialData: {selected1: false, selected2: false, data: dataByParamTires, selectedSubcategoryAttrs: selectedSubcategoryAttrs} } })
            }
        } else {
            if(selected2) {
                if(dataByCarDiscs.engine.length > 0) {
                    GetByCarParams()
                }
            } else {
                navigate(`${StorePageRoot}`, { state: { searchInitialData: {selected1: true, selected2: false, data: dataByParamDiscs, selectedSubcategoryAttrs: selectedSubcategoryAttrs} } })
            }
        }
    }


    return (
        <>
            <div className="search-block">
                <div className="search-block-btns h-18-400-j">
                    <button className={`h-18-400-j black ${!selected1 ? 'active' : ''}`} onClick={() => setSelected1(false)}>Подбор шин</button>
                    <button className={`h-18-400-j black ${selected1 ? 'active' : ''}`} onClick={() => setSelected1(true)}>Подбор дисков</button>
                </div>
                <div className="search-block-main">
                    {!selected1 && //Шины
                    <>
                        <div className="col-10">
                            <div className="row-20-a h-14-400-i">
                                <span style={selected2 ? {color: '#98A2B3'} : {color: '#000000'}}>По параметрам</span>
                                <ToggleBtn toggleActive={selected2} onChange={(e) => setSelected2(e)} fill={true} />
                                <span style={!selected2 ? {color: '#98A2B3'} : {color: '#000000'}}>По автомобилю</span>
                            </div>
                            {!selected2 &&
                            <div className="row-20-a h-14-400-i">
                                <span>Категория</span>
                                <div style={{width: '200px'}}>
                                    <CustomSelect allOption={allSubcategoryAttrs} ActiveItems={selectedSubcategoryAttrs || ''} setActiveItems={(e) => setSelectedSubcategoryAttrs(e)} single={true} enclosure={false} placeholder={'Выбрать...'} disabled={!(allSubcategoryAttrs.length>0)}/>
                                </div>
                            </div>}
                        </div>
                        {selected2 ?  //Шины Автомобиль
                        <div className="col-15">
                            <div className="row-10">
                                <CustomSelect allOption={allTiresMarks} ActiveItems={dataByCarTires?.stamp || ''} setActiveItems={(e) => setDataByCarTires({...dataByCarTires, stamp: e})} single={true} enclosure={false} placeholder={'Марка'} disabled={!(allTiresMarks.length>0)}/>
                                <CustomSelect allOption={allTiresModels} ActiveItems={dataByCarTires?.model || ''} setActiveItems={(e) => setDataByCarTires({...dataByCarTires, model: e})} single={true} enclosure={false} placeholder={'Модель'} disabled={!(dataByCarTires?.stamp.length > 0 && allTiresModels.length>0)}/>
                            </div>
                            <div className="row-10">
                                <CustomSelect allOption={allTiresYear} ActiveItems={dataByCarTires?.year || ''} setActiveItems={(e) => setDataByCarTires({...dataByCarTires, year: e})} single={true} enclosure={false} placeholder={'Год выпуска'} disabled={!(dataByCarTires?.model.length > 0 && allTiresYear.length>0)}/>
                                <CustomSelect allOption={allTiresTrim} ActiveItems={dataByCarTires?.engine || ''} setActiveItems={(e) => setDataByCarTires({...dataByCarTires, engine: e})} single={true} enclosure={false} placeholder={'Двигатель'} disabled={!(dataByCarTires?.year.length > 0 && allTiresTrim.length>0)}/>
                            </div>
                        </div>
                        :  //Шины Параметры
                        <div className="col-15">
                            <div className="row-10">
                                <CustomSelect allOption={allTiresWidth} ActiveItems={dataByParamTires?.width || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, width: e})} single={true} enclosure={false} placeholder={'Ширина, мм'} disabled={!(allTiresWidth.length > 0 && selectedSubcategoryAttrs[0]?.value && !tiresAttrLoading)}/>
                                <CustomSelect allOption={allTiresProfile} ActiveItems={dataByParamTires?.profile || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, profile: e})} single={true} enclosure={false} placeholder={'Профиль'} disabled={!(allTiresProfile.length > 0 && selectedSubcategoryAttrs[0]?.value && !tiresAttrLoading)}/>
                                <CustomSelect allOption={allTiresDiameter} ActiveItems={dataByParamTires?.diameter || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, diameter: e})} single={true} enclosure={false} placeholder={'Диаметр'} disabled={!(allTiresDiameter.length > 0 && selectedSubcategoryAttrs[0]?.value && !tiresAttrLoading)}/>
                            </div>
                            <div className="row-10">
                                <CustomSelect allOption={allTiresSeasonality} ActiveItems={dataByParamTires?.seasonality || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, seasonality: e})} single={true} enclosure={false} placeholder={'Сезонность'} disabled={!(allTiresSeasonality.length > 0 && selectedSubcategoryAttrs[0]?.value && !tiresAttrLoading)}/>
                                <CustomSelect allOption={allTiresType} ActiveItems={dataByParamTires?.type || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, type: e})} single={true} enclosure={false} placeholder={'Шипы'} disabled={!(allTiresType.length > 0 && selectedSubcategoryAttrs[0]?.value && !tiresAttrLoading)}/>
                            </div>
                            <div className="row-10">
                                <CustomSelect allOption={allTiresManufacturer} ActiveItems={dataByParamTires?.manufacturer || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, manufacturer: e})} single={true} enclosure={false} placeholder={'Производитель'} disabled={!(allTiresManufacturer.length > 0 && selectedSubcategoryAttrs[0]?.value && !tiresAttrLoading)}/>
                            </div>
                        </div>
                        }
                    </>
                    }
                    {selected1 &&  //Диски
                    <>
                        <div className="col-10">
                            <div className="row-20-a h-14-400-i">
                                <span style={selected2 ? {color: '#98A2B3'} : {color: '#000000'}}>По параметрам</span>
                                <ToggleBtn toggleActive={selected2} onChange={(e) => setSelected2(e)} fill={true}/>
                                <span style={!selected2 ? {color: '#98A2B3'} : {color: '#000000'}}>По автомобилю</span>
                            </div>
                            {!selected2 &&
                            <div className="row-20-a h-14-400-i">
                                <span>Категория</span>
                                <div style={{width: '200px'}}>
                                    <CustomSelect allOption={allSubcategoryAttrs} ActiveItems={selectedSubcategoryAttrs || ''} setActiveItems={(e) => setSelectedSubcategoryAttrs(e)} single={true} enclosure={false} placeholder={'Выбрать...'} disabled={!(allSubcategoryAttrs.length>0)}/>
                                </div>
                            </div>}
                        </div>
                        {selected2 ? //Диски Автомобиль
                        <div className="col-15">
                            <div className="row-10">
                                <CustomSelect allOption={allDisksMarks} ActiveItems={dataByCarDiscs?.stamp || ''} setActiveItems={(e) => setDataByCarDiscs({...dataByCarDiscs, stamp: e})} single={true} enclosure={false} placeholder={'Марка'} disabled={!(allDisksMarks.length>0)}/>
                                <CustomSelect allOption={allDisksModels} ActiveItems={dataByCarDiscs?.model || ''} setActiveItems={(e) => setDataByCarDiscs({...dataByCarDiscs, model: e})} single={true} enclosure={false} placeholder={'Модель'} disabled={!(dataByCarDiscs?.stamp.length > 0 && allDisksModels.length>0)}/>
                            </div>
                            <div className="row-10">
                                <CustomSelect allOption={allDisksYear} ActiveItems={dataByCarDiscs?.year || ''} setActiveItems={(e) => setDataByCarDiscs({...dataByCarDiscs, year: e})} single={true} enclosure={false} placeholder={'Год выпуска'} disabled={!(dataByCarDiscs?.model.length > 0 && allDisksYear.length>0)}/>
                                <CustomSelect allOption={allDisksTrim} ActiveItems={dataByCarDiscs?.engine || ''} setActiveItems={(e) => setDataByCarDiscs({...dataByCarDiscs, engine: e})} single={true} enclosure={false} placeholder={'Двигатель'} disabled={!(dataByCarDiscs?.year.length > 0 && allDisksTrim.length>0)}/>
                            </div>
                        </div>
                        :  //Диски Параметры
                        <div className="col-15">
                            <div className="row-10">
                                <CustomSelect allOption={allDisksWidth} ActiveItems={dataByParamDiscs?.width || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, width: e})} single={true} enclosure={false} placeholder={'Ширина, дюйм'} disabled={!(allDisksWidth.length > 0 && selectedSubcategoryAttrs[0]?.value && !disksAttrLoading)}/>
                                <CustomSelect allOption={allDisksDiameter} ActiveItems={dataByParamDiscs?.diameter || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, diameter: e})} single={true} enclosure={false} placeholder={'Диаметр, дюйм'} disabled={!(allDisksDiameter.length > 0 && selectedSubcategoryAttrs[0]?.value && !disksAttrLoading)}/>
                                <CustomSelect allOption={allDisksPCD} ActiveItems={dataByParamDiscs?.pcd || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, pcd: e})} single={true} enclosure={false} placeholder={'PCD (крепёж)'} disabled={!(allDisksPCD.length > 0 && selectedSubcategoryAttrs[0]?.value && !disksAttrLoading)}/>
                            </div>
                            <div className="row-10">
                                <CustomSelect allOption={allDisksET} ActiveItems={dataByParamDiscs?.et || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, et: e})} single={true} enclosure={false} placeholder={'ET (вылет)'} disabled={!(allDisksET.length > 0 && selectedSubcategoryAttrs[0]?.value && !disksAttrLoading)}/>
                                <CustomSelect allOption={allDisksHub} ActiveItems={dataByParamDiscs?.hub || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, hub: e})} single={true} enclosure={false} placeholder={'Ступица'} disabled={!(allDisksHub.length > 0 && selectedSubcategoryAttrs[0]?.value && !disksAttrLoading)}/>
                                <CustomSelect allOption={allDisksType} ActiveItems={dataByParamDiscs?.type || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, type: e})} single={true} enclosure={false} placeholder={'Тип'} disabled={!(allDisksType.length > 0 && selectedSubcategoryAttrs[0]?.value && !disksAttrLoading)}/>
                            </div>
                            <div className="row-10">
                                <CustomSelect allOption={allDisksManufacturer} ActiveItems={dataByParamDiscs?.manufacturer || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, manufacturer: e})} single={true} enclosure={false} placeholder={'Производитель'} disabled={!(allDisksManufacturer.length > 0 && selectedSubcategoryAttrs[0]?.value && !disksAttrLoading)}/>
                            </div>
                        </div>
                        }
                    </>
                    }
                    <button className="base-btn white h-14-400-j" onClick={submit}>Найти</button>
                    <div className="row-40">
                        {/* <button className="row-10-a h-14-400-i gr5">
                            <img src={img_pack.input_disc_icon} alt="ic" />
                            <span>Где смотреть параметры?</span>
                        </button> */}
                        <button className="row-10-a h-14-400-i gr5" onClick={() => navigate('/contact_po', { state: { prevLocation: location } })}>
                            <img src={img_pack.input_help_icon} alt="ic" />
                            <span>Помощь в подборе</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SearchBlock;