import { useState } from "react";
import { Bounce, toast } from "react-toastify";

export const useFetching = (callback, useToastPromise = false) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const fetching = async (...params) => {
        setIsLoading(true);
        setError('');

        try {
            if (useToastPromise) {
                // Используем toast.promise для этого запроса
                await toast.promise(
                    callback(...params), // Убедитесь, что callback возвращает промис
                    {
                        pending: {
                          render(){
                            return "Загрузка"
                          },
                        },
                        success: {
                          render({data}){
                            return `${data.data.mes}`
                          },
                        },
                        error: {
                            render({ data }) {
                                const { slug, mes, errors } = data.response?.data || {};
                                const errorMessage = slug || mes || errors || 'Что-то пошло не так';
                                setError(errorMessage); // Устанавливаем состояние ошибки
                                return errorMessage; // Возвращаем сообщение об ошибке
                            },
                        },
                      }
                );
            } else {
                // Обычная обработка
                const res = await callback(...params);
                if (res?.data?.mes) {
                    toast.success(`${res.data.mes}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
                }
            }
        } catch (e) {
            if (!useToastPromise) {
                setError(e);
                const { slug, mes, errors } = e.response?.data || {};
                const errorMessage = slug || mes || errors || 'Что-то пошло не так';
                toast.error(`${errorMessage}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            } 
        } finally {
            setIsLoading(false);
        }
    };

    return [fetching, isLoading, error];
};
