import React, { useEffect } from 'react';
import DG from '2gis-maps';

const Map2gis = () => {
    useEffect(() => {
        const map = DG.map('map', {
            center: [55.168647, 61.270345], // Координаты центра карты
            zoom: 20,
        });
        DG.marker([55.168792, 61.270191]).addTo(map); // Добавляем маркер
    }, []);

    return (
        <div id="map" style={{ width: '100%', height: '500px' }} />
    );
};

export default Map2gis;
