import React, { useContext, useEffect, useState } from "react"
import ProductCatChoice from "./ProductCatChoice";
import PriceRangeSlider from "./PriceRangeSlider";
import { useFetching } from "../../hooks/UseFetching";
import GetStore from "../../API/GetStore";
import { useLocation } from "react-router";
import { Context } from "../../App";

const FilterSearchBlock = ({rangePrice, setRangePrice, sortingProducts, query, setQuery, selectedCat, setSelectedCat, selectedBrand, setSelectedBrand, maxPrice}) => {

    const {allBrandsSide, allCatsSide} = useContext(Context)

    const location = useLocation()
    let initialBrand = location.state?.initialBrand

    useEffect(() => {
        if(initialBrand !== '' && initialBrand !== undefined && initialBrand?.slug !== '') {
            setSelectedBrand(initialBrand)
            location.state.initialBrand = ''
            window.history.replaceState({}, '')
        }
    },[initialBrand])

    return (
        <>
            <div className="h-16-700-i gr7">Поиск по товарам</div>
                <input type="text" placeholder="Поиск по товарам..." className="base-input" value={query || ''} onChange={(e) => setQuery(e.target.value)}/>
                <span style={{borderBottom: '2px solid #D0D5DD'}} />
                <div className="h-16-700-i gr7">Фильтрация по цене</div>
                <PriceRangeSlider rangePrice={rangePrice} setRangePrice={setRangePrice} minPrice={0} maxPrice={maxPrice}/>
                <button className="base-btn h-14-500-i white" onClick={() => sortingProducts()}>Фильтровать</button>
                <div className="h-16-700-i gr7">Категории товаров</div>
                <div className="col-10">
                    <ProductCatChoice res={allCatsSide} selectedCat={selectedCat} setSelectedCat={setSelectedCat}></ProductCatChoice>
                </div>
                <span style={{borderBottom: '2px solid #D0D5DD'}} />

                <div className="h-16-700-i gr7">Бренды</div>
                <div className="col-10">
                    {(allBrandsSide?.length > 0) && allBrandsSide?.map((el) => (
                        // console.log(`1)${selectedBrand?.slug}  , ${el.slug}, ${selectedBrand?.slug === el.slug}`),
                        <button className={`product_choicecat_btn h-14-600-i gr6 ${selectedBrand?.slug === el.slug ? 'active' : ''}`} key={el.id} onClick={() => {selectedBrand?.slug === el.slug ? setSelectedBrand({name: '', slug: ''}) : setSelectedBrand({name: el.name, slug: el.slug})}}>
                            <span>{el.name}</span>
                            <span className="ba-total">{el.product_count ? el.product_count : 0}</span>
                        </button>
                    ))}
                </div>
        </>
    )
}

export default FilterSearchBlock;