import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import img_pack from "../../img/img";
import { useLocation } from "react-router";

const CustomChoiceDpd = ({el, q, children, selectedCat, setSelectedCat, CatChoiceClick}) => {

    const queryParams = new URLSearchParams(window.location.search);
    const [dpOpen, setDpOpen] = useState(false);
    const curContent = useRef()

    const location = useLocation()
    let initialCat = location.state?.initialCat

    useEffect(() => {
        if(location?.state?.initialCat !== '' && location?.state?.initialCat !== undefined && location?.state?.initialCat?.slug !== '') {
            if(location?.state?.initialCat?.slug === el.slug) {
                if (el.children.length > 0) {
                    click()
                } else {
                    setSelectedCat({
                        name: initialCat.name,
                        slug: initialCat.slug,
                    })
                }
            } else {
                if( selectedCat?.slug !== initialCat.slug) {
                    setSelectedCat({
                        name: initialCat.name,
                        slug: initialCat.slug,
                    })
                }
            }
            location.state.initialCat = '';
            initialCat = '';
            window.history.replaceState({}, '')
        }
    },[location?.state?.initialCat])

    function click() {
        setDpOpen(!dpOpen)
        const contentDpd = curContent.current
        const curContentHeight = contentDpd?.scrollHeight;

        if(contentDpd.classList.contains('open')) {
            contentDpd.classList.remove('open')
            contentDpd.style.maxHeight = '0';
        } else {
            contentDpd.classList.add('open')
            contentDpd.style.maxHeight = curContentHeight + 'px';
        }
    }

    const hasSlugInTree = (node, slugToFind) => {
        let found = false;
        function search(currentNode) {
            if (currentNode.slug === slugToFind) {
                found = true;
                return; // Если найдено, можем сразу выйти
            }
            if (currentNode.children && currentNode.children.length > 0) {
                for (const child of currentNode.children) {
                    search(child);
                    if (found) return; // Прекращаем поиск, если уже нашли
                }
            }
        }
        search(node);
        return found;
    };

    const prevdpOpen = useRef(dpOpen);
    useEffect(() => {
        if (prevdpOpen.current !== dpOpen) {
            if(dpOpen) {
                if(initialCat === undefined || initialCat === '') {
                    if(selectedCat?.slug === el.slug) {
                        setSelectedCat({
                            name: '',
                            slug: '',
                        })
                    } else {
                        setSelectedCat({
                            name: el.name,
                            slug: el.slug,
                        })
                    }
                }
            } else {
                if(selectedCat?.slug !== '') {
                    if(selectedCat?.slug === el.slug || hasSlugInTree(el, selectedCat?.slug)) {
                        setSelectedCat({
                            name: '',
                            slug: '',
                        })
                    }
                }
            }
            prevdpOpen.current = dpOpen
        }
    },[dpOpen])

    useEffect(() => {
        if(dpOpen && selectedCat?.slug !== '') {
            if(selectedCat?.slug !== el.slug) {
                if(!hasSlugInTree(el, selectedCat?.slug)) {
                    click()
                }
            }
        }
        if(dpOpen && selectedCat?.slug === '') {
            click()
        }
    },[selectedCat])

    return (
        <>
        {el.children.length > 0 ?
        <div className={`choice-dpd ${dpOpen ? 'active' : ''}`}>
            <button onClick={click} className={`product_choicecat_btn h-14-600-i gr6 ${selectedCat?.slug === el.slug ? 'active' : ''}`}>
                <span>{el.name}</span>
                <div>
                    <span className="ba-total">{q}</span>
                    <img src={img_pack.dpd_icon} alt="dpd_icon" style={dpOpen ? {transform: 'rotate(180deg)'} : {}} />
                </div>
            </button>
            <div className={`dpd-content ${dpOpen ? 'open' : ''}`} ref={curContent}>
                {children}
            </div>
        </div>
        :
        <button className={`product_choicecat_btn h-14-600-i gr6 ${selectedCat?.slug === el.slug ? 'active' : ''}`} key={el.id} onClick={() => CatChoiceClick(el)}>
            <span>{el.name}</span>
            <span className="ba-total">{el.product_total}</span>
        </button>
        }
        </>
    )
}

export default CustomChoiceDpd;