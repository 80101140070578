import React, { useEffect, useState } from "react"
import slugify from 'slugify';
import { useLocation, useNavigate, useParams } from "react-router";
import img_pack from "../img/img";
import { AdminUsersRedRootBase, AdminUsersRolesRedRootBase, AdminUsersRolesRoot, AdminUsersRoot, egoda_url } from "../utils/consts";
import { useFetching } from "../hooks/UseFetching";
import GetUsers from "../API/GetUsers";
import RolesRedTabs from "./components/RolesRedTabs";

const AdminUsersRolesRedPage = () => {

    const [rolesRedTabsData, setRolesRedTabsData] = useState({
        "name": '',
        "slug": '',
        "default_for_client": false,
        "access_admin_panel": false,
        "view_dashboard": false,
        "edit_dashboard": false,
        "view_shop": false,
        "edit_shop": false,
        "view_services": false,
        "edit_services": false,
        "view_specialists": false,
        "edit_specialists": false,
        "view_orders": false,
        "edit_orders": false,
        "view_feedback": false,
        "edit_feedback": false,
        "view_articles": false,
        "edit_articles": false,
        "view_media": false,
        "edit_media": false,
        "view_users": false,
        "edit_users": false,
        "view_newsletters": false,
        "edit_newsletters": false,
        "view_settings": false,
        "edit_settings": false
    })

    const {slugParam} = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const isEdit = location.pathname.includes(AdminUsersRolesRedRootBase)

    // Базовые запросы \ первичный рендер
    // Получение поста при изменении поста

    const [GetRole] = useFetching( async (slugParam) => {
        const res = await GetUsers.GetRole(slugParam)
        console.log('GetRole complete:' , res.data)
        const roles = res.data.detail
        setRolesRedTabsData(prevState => ({
            ...prevState,
            name: roles?.name || prevState.name,
            slug: roles?.slug || prevState.slug,
            default_for_client: roles?.default_for_client || prevState.default_for_client,
            access_admin_panel: roles?.access_admin_panel || prevState.access_admin_panel,
            view_dashboard: roles?.view_dashboard || prevState.view_dashboard,
            edit_dashboard: roles?.edit_dashboard || prevState.edit_dashboard,
            view_shop: roles?.view_shop || prevState.view_shop,
            edit_shop: roles?.edit_shop || prevState.edit_shop,
            view_services: roles?.view_services || prevState.view_services,
            edit_services: roles?.edit_services || prevState.edit_services,
            view_specialists: roles?.view_specialists || prevState.view_specialists,
            edit_specialists: roles?.edit_specialists || prevState.edit_specialists,
            view_orders: roles?.view_orders || prevState.view_orders,
            edit_orders: roles?.edit_orders || prevState.edit_orders,
            view_feedback: roles?.view_feedback || prevState.view_feedback,
            edit_feedback: roles?.edit_feedback || prevState.edit_feedback,
            view_articles: roles?.view_articles || prevState.view_articles,
            edit_articles: roles?.edit_articles || prevState.edit_articles,
            view_media: roles?.view_media || prevState.view_media,
            edit_media: roles?.edit_media || prevState.edit_media,
            view_users: roles?.view_users || prevState.view_users,
            edit_users: roles?.edit_users || prevState.edit_users,
            view_newsletters: roles?.view_newsletters || prevState.view_newsletters,
            edit_newsletters: roles?.edit_newsletters || prevState.edit_newsletters,
            view_settings: roles?.view_settings || prevState.view_settings,
            edit_settings: roles?.edit_settings || prevState.edit_settings,
        }));
        
    })

    useEffect(() => {
        if(slugParam && isEdit) {
            GetRole(slugParam)
        }
    },[slugParam])

    // Отмена базового поведения формы на Enter
    function handleKeyPress(e) {  
        if (e.keyCode === 13) {
          e.preventDefault();
        }
    }
    useEffect(() => {
        const form = document.querySelector(".new_articles_form")
        form.addEventListener("keypress", handleKeyPress);
        return () => {
            if (form) {
                form.removeEventListener("keypress", handleKeyPress);
            }
        };
    },[])

    const [CreateRole] = useFetching( async (data) => {
        const res = await GetUsers.CreateRole(data)
        // console.log('CreateRole complete:' , res.data)
        navigate(`${AdminUsersRolesRoot}`)
        return res;
    })
    const [UpdateRole] = useFetching( async (data) => {
        const res = await GetUsers.UpdateRole(data, slugParam)
        // console.log('UpdateRole complete:' , res.data)
        navigate(`${AdminUsersRolesRoot}`)
        return res;
    })
    
    function submit(e) {
        e.preventDefault()

        if(isEdit) {
            UpdateRole(rolesRedTabsData)
        } else {
            CreateRole(rolesRedTabsData)
        }
    }

    function slugifyTitle(e) {
        setRolesRedTabsData(prevState => ({
            ...prevState,
            name: e.target.value,
            slug: slugify(e.target.value, { lower: true })
        }));
    }

    return (
        <form onSubmit={submit} className="new_articles_form">
            <div className="new_articles_body">
            <div className="col-5">
                    <input onChange={slugifyTitle} name="title" className="base-input h-16-400-i gr7" placeholder="Наименование роли" type="text" required value={rolesRedTabsData?.name || ''} />
                    <input style={{padding: '5px 14px'}} name="slug" className="base-input h-12-400-i gr7" placeholder="slug" type="text" value={rolesRedTabsData?.slug} onChange={(e) => setRolesRedTabsData({...rolesRedTabsData, slug: e.target.value })} required/>
                </div>
                <div className="h-16-500-i" style={{padding: '10px'}}>Настройки роли</div>
                <RolesRedTabs rolesRedTabsData={rolesRedTabsData} setRolesRedTabsData={setRolesRedTabsData}></RolesRedTabs>
            </div>
            <div className="new_articles_r_sidebar">
                <button className="admin_save_btn" type="submit">
                    <img src={img_pack.save_icon} alt="save_icon" />
                    <span className="h-14-500-i white">Сохранить</span>
                </button>
            </div>
        </form>
        
    )
}

export default AdminUsersRolesRedPage;