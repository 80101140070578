import React, { useContext, useEffect, useState } from "react"
import PriceRangeSlider from "./PriceRangeSlider";
import { useFetching } from "../../hooks/UseFetching";
import GetStore from "../../API/GetStore";
import ProductCatChoice from "./ProductCatChoice";
import SotreSearchBlock from "./StoreSearchBlock";
import FilterSearchBlock from "./FilterSearchBlock";

const StoreLsidebar = ({rangePrice, setRangePrice, sortingProducts, query, setQuery, selectedCat, setSelectedCat, selectedBrand, setSelectedBrand, AllParams, setAllParams, maxPrice}) => {

    return (
       <>
        <div className="store-lsidebar">
            <div className="base-lsidebar-col">
                <SotreSearchBlock AllParams={AllParams} setAllParams={setAllParams} setSelectedCat={setSelectedCat} setSelectedBrand={setSelectedBrand}/>
            </div>

            <div className="base-lsidebar-col">
                <FilterSearchBlock rangePrice={rangePrice} setRangePrice={setRangePrice} sortingProducts={sortingProducts} query={query} setQuery={setQuery} selectedCat={selectedCat} setSelectedCat={setSelectedCat} selectedBrand={selectedBrand} setSelectedBrand={setSelectedBrand} maxPrice={maxPrice}/>
            </div>
        </div>
       </>
    )
}

export default StoreLsidebar;