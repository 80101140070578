import React from 'react';

const EditorAdd = ({ blocks }) => {
  return (
    <div className="editor-content-blocks">
      {blocks?.map((el) => {
        switch (el.type) {
          case 'header':
            return React.createElement(
              `h${el.data.level}`,
              { key: el.id, id: el.id, dangerouslySetInnerHTML: { __html: el.data.text } }
            );
          case 'paragraph':
            return React.createElement(
              'p',
              { key: el.id, dangerouslySetInnerHTML: { __html: el.data.text } }
            );
          case 'quote':
            return (
              <blockquote
                key={el.id}
                dangerouslySetInnerHTML={{ __html: el.data.text }}
              />
            );
          case 'checklist':
            return (
              <ul key={el.id}>
                {el.data.items.map((item, index) => (
                  <li key={index} className="checklist-item">
                    <input type="checkbox" disabled checked={item.checked} />
                    <span dangerouslySetInnerHTML={{ __html: item.text }} />
                  </li>
                ))}
              </ul>
            );
          case 'list':
            return (
              <ul key={el.id} style={el.data.style === "ordered" ? {listStyleType: 'decimal', marginLeft: '20px'} : {listStyleType: 'disc', marginLeft: '20px'}}>
                {el.data.items.map((item, index) => (
                  <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
                ))}
              </ul>
            );
          default:
            return (
              <span
                key={el.id}
                dangerouslySetInnerHTML={{ __html: el.data.text || el.data.code }}
              />
            );
        }
      })}
    </div>
  );
};

export default EditorAdd;
