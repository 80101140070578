import React, { useContext, useEffect, useState } from "react"
import { Context } from "../../App";
import img_pack from "../../img/img";
import globalFunction, { AddToCart, ProductPageRootBase, egoda_url } from "../../utils/consts";
import { Link } from "react-router-dom";
import GetCart from "../../API/GetCart";
import { useFetching } from "../../hooks/UseFetching";
import ProductCounter from "./ProductCounter";
import LazyImage from "./LazyImage";

const ProductExample_line = ({res}) => {

    const [count, setCount] = useState(1);
    const {windowWidth, AddCart} = useContext(Context)

    function AddToCart(id, quantity, override) {
        const data = new FormData();
        data.append("product_id", id || '');
        data.append("quantity", quantity || '');
        data.append("override", override || false);

        // console.log('asd', id, quantity, override)
    
        AddCart(data)
    };

    return (
        <div className="product-example_line">
            <Link to={`${ProductPageRootBase}${res.category_slug}/${res.slug}`}>
                <div className="product-example-img">
                    {res.image_url ?
                    <LazyImage src={`${egoda_url}${res.image_url}`} alt="product_img" /> 
                    :<LazyImage style={{objectFit: 'contain', maxHeight: '160px', maxWidth: '160px'}} src={`${img_pack.product_example}`} alt="img" />}
                </div>
            </Link>
            <div className="product-example-content">
                <div className="col-10">
                    <div className="h-14-400-i gr8"><Link to={`${ProductPageRootBase}${res.category_slug}/${res.slug}`}>{res?.name}</Link></div>
                    <div className="h-12-400-i gr5">Артикул: {res?.item_number || '-'}</div>
                </div>
                <div className="col-10">
                    <div className="h-20-400-j">{globalFunction.withSpaces(globalFunction.split(res?.price)[0])} ₽</div>
                    <div className="h-12-400-i gr5">В наличии ({res?.quantity || 0} шт)</div>
                </div>
                <div className="col-10">
                    <ProductCounter onChange={(e) => setCount(e) } initialCount={count} />
                    <button className="cart-btn h-14-400-i" onClick={() => AddToCart(res.id, count)}>В корзину</button>
                </div>
            </div>  
        </div>
    )
}

export default ProductExample_line;