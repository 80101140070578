import React, { useContext, useEffect, useState } from "react"
import img_pack from "../../img/img";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../App";
import CustomDpd from "./Custom_dp_link";
import { ExchangeAndRefundRoot, FaqPageRoot, PaymentAndDeliveryRoot, PrivacyPolicyRoot, StorePageRoot } from "../../utils/consts";
import LazyImage from "./LazyImage";

const Footer = () => {

    const {windowWidth,allBrandsSide, allCatsSide} = useContext(Context)
    // const navigate = useNavigate()

    // const [selectedCat, setSelectedCat] = useState('');
    // const [selectedBrand, setSelectedBrand] = useState('');

    // useEffect(() => {
    //     window.scrollTo(0,0)
    //     if(selectedBrand !== '') {
    //         navigate(`${StorePageRoot}`, { state: { initialBrand: selectedBrand } })
    //     }
    //     if(selectedCat !== '') {
    //         navigate(`${StorePageRoot}`, { state: { initialCat: selectedCat } })
    //     }
    // },[selectedCat, selectedBrand])


    return (
        <footer>
            <div className="container">
                <div style={{borderBottom: '1px solid #EAECF0'}}>
                    <div>
                        <Link to={'/'} className="footer-logo">
                            <LazyImage src={img_pack.footer_logo} alt="logo" />
                            <LazyImage src={img_pack.footer_logoTxt} alt="logoTxt" />
                        </Link>
                    </div>
                    {windowWidth > 650 ?
                    <div>
                        <div className="h-14-400-i gr2">
                            <span className="h-14-600-i white">Каталог товаров</span>
                            <Link to={StorePageRoot}>Магазин</Link>
                            {/* <Link to={'#!'}>Бренды</Link> */}
                        </div>
                        {/* <div className="h-14-400-i gr2">
                            <span className="h-14-600-i white">О компании</span>
                            <Link to={'#!'}>О нас</Link>
                        </div> */}
                        <div className="h-14-400-i gr2">
                            <span className="h-14-600-i white">Помощь</span>
                            <Link to={FaqPageRoot}>Вопросы и ответы</Link>
                            <Link to={PaymentAndDeliveryRoot}>Доставка и оплата</Link>
                            <Link to={ExchangeAndRefundRoot}>Обмен и возврат</Link>
                            <Link to={PrivacyPolicyRoot}>Политика конфиденциальности</Link>
                        </div>

                    </div>
                    : 
                    <div>
                        <div className="col" style={{padding: '10px 16px', textAlign: 'center'}}>
                            <Link style={{padding: '10px 0'}} to={StorePageRoot} className="h-14-500-i white">Магазин</Link>
                            <Link style={{padding: '10px 0'}} to={FaqPageRoot} className="h-14-500-i white">Вопросы и ответы</Link>
                            <Link style={{padding: '10px 0'}} to={PaymentAndDeliveryRoot} className="h-14-500-i white">Доставка и оплата</Link>
                            <Link style={{padding: '10px 0'}} to={ExchangeAndRefundRoot} className="h-14-500-i white">Обмен и возврат</Link>
                            <Link style={{padding: '10px 0'}} to={PrivacyPolicyRoot} className="h-14-500-i white">Политика конфиденциальности</Link>
                        </div>
                    </div>
                    }
                </div>
                <div>
                    <div className="row-10-a h-16-400-i white">
                        <div>2024 ООО «ТОРГОВАЯ КОМПАНИЯ АРИСПЕЦСИТИ 74» - купить шины и диски онлайн!</div>
                        {/* {windowWidth > 700 &&
                            <>
                                <span className="line_1"/>
                                <Link className="underline" to={'#!'}>Карта сайта</Link>
                            </>
                        } */}
                    </div>
                    {/* {windowWidth > 1000 &&
                    <div className="row-10-a h-12-500-i white">
                        <Link className="underline" to={'#!'}>Ссылка</Link>
                        <span className="line_1"/>
                        <Link className="underline" to={'#!'}>Ссылка</Link>
                        <span className="line_1"/>
                        <Link className="underline" to={'#!'}>Ссылка</Link>
                    </div>
                    } */}
                </div>
            </div>
        </footer>
    )
}

export default Footer;