import axios from "axios"
import { egoda_url } from "../utils/consts";

export default class GetMain {
    // Получить список товаров (пользователь)
    static async GetMainProducts(data, page) {
        const response = await axios.post(`${egoda_url}/index/product_list/${page}/`, data,
        {
            withCredentials: true
        });

        return response;
    }
    // Получить список брендов (пользователь)
    static async GetMainBrands(data, page) {
        const response = await axios.post(`${egoda_url}/index/brand_list/${page}/`, data,
        {
            withCredentials: true
        });

        return response;
    }
    // ! Получить дашборд
    static async GetDashboard(data) {
        const response = await axios.post(`${egoda_url}/settings_site/dashboard/`, data,
        {
            withCredentials: true
        });

        return response;
    }
}