import React, { useContext, useEffect, useRef, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import img_pack from "../img/img";
import { useFetching } from "../hooks/UseFetching";
import Pagination from "./components/Pagination";
import globalFunction, { AdminArticlesCatRedRootBase, AdminContactRedRootBase, AdminOrderRedRootBase, AdminStoreBrandsNewRoot, AdminStoreBrandsRedRootBase, AdminStoreCatNewRoot, AdminStoreCatRedRootBase, AdminStoreRedRootBase, feedbackStatusMap } from "../utils/consts";
import GetStore from "../API/GetStore";
import GetFeedback from "../API/GetFeedback";
import { format } from "date-fns";
import GetOrder from "../API/GetOrder";
import { Context } from "../App";

const AdminOrderPage = () => {

    const {setOrDeleteParam, queryParams} = useContext(Context)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(null)
    const [totalPosts, setTotalPosts] = useState(null)

    const [curId, setCurId] = useState('');
    const [itemPodata, setItemPodata] = useState({title: 'Заказ будет удален', desc: 'Вы уверены, что хотите удалить заказ безвозвратно?'});

    const [orders, setOrders] = useState([])
    const location = useLocation()
    const navigate = useNavigate()

    function checkClick(e) {
        if (e.target.classList.contains('active')) {
            e.target.classList.remove('active')
        } else {
            e.target.classList.add('active')
        }
    }

    const [GetOrdersList] = useFetching( async (page) => {
        const res = await GetOrder.GetOrdersList(page)
        setOrders(res.data.data)
        setTotalPages(res.data?.total_pages)
        setTotalPosts(res.data?.total)
        // console.log('GetOrdersList complete:' , res.data)
        navigate(`?${queryParams.toString()}`);
    })

    const prevPageRef = useRef(currentPage);
    useEffect(() => {
        if (prevPageRef.current !== currentPage) {
            setOrDeleteParam('page', currentPage);
            GetOrdersList(currentPage);
            prevPageRef.current = currentPage;
        }
    }, [currentPage]);
    useEffect(() => {
        if(queryParams.get('page') && +queryParams.get('page') > 1) {
            setCurrentPage(+queryParams.get('page'))
        } else {
            GetOrdersList(1)
        }
    },[])

    // Удаление бренда
    function deleteRequest(id) {
        setCurId(id)
        navigate('/item_po', { state: { prevLocation: location, itemPodata: itemPodata } })
    }

    const [DeleteOrder] = useFetching( async (id) => {
        const res = await GetOrder.DeleteOrder(id)
        // console.log('DeleteOrder complete:' , res.data)
        if(currentPage !== 1) {
            setCurrentPage(1)
        } else {
            GetOrdersList(currentPage)
        }
        return res;
    })

    useEffect(() => {
        if (location.state?.confirmPo) {
            // Обнуление состояния confirm в истории
            location.state.confirmPo = undefined
            window.history.replaceState({}, '')
            // Логика на confirmPo
            DeleteOrder(curId)
            setCurId('')
        }
    }, [location.state])

    return (
        <div className="admin_content_base">
            <div className="admin_content_header">
                <div className="row-10-a">
                    <div className="h-18-500-i gr8">Заказы</div>
                    <span className="h-12-500-i ba-or">{totalPosts} шт</span>
                </div>
            </div>

            <table className="articles_admin_table">
                <thead>
                    <tr>
                        <th>
                            <div>
                                <span className="check_box_base" onClick={checkClick}/>
                                Заказ
                            </div>
                        </th>
                        <th>
                            <div>
                                Дата
                                <img src={img_pack.dpd_icon} alt="dpd_icon" />
                            </div>
                        </th>
                        <th>
                            <div>
                                Статус
                                <img src={img_pack.dpd_icon} alt="dpd_icon" />
                            </div>
                        </th>
                        <th>
                            <div>
                                Цена
                            </div>
                        </th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {orders?.map((el) => (
                        <tr key={el.id}>
                            <td className="tcats-name">
                                <div>
                                    <span className="check_box_base" onClick={checkClick}/>
                                    <Link to={`${AdminOrderRedRootBase}${el.id}`}>
                                        <span className="h-14-500-i gr7">№{el.id}-{el.first_name}</span>
                                    </Link>
                                </div>
                            </td>
                            <td className="t-date">
                                <span className="h-12-500-i gr7">{el.created ? format(new Date(el.created), 'dd.MM.yyyy') : '-' }</span>
                            </td>
                            <td className="t-status">
                                <div className={'ba h-12-500-i'} style={{maxWidth: 'max-content'}}>
                                    <span className="h-12-500-i">{el.status.status_name}</span>
                                </div>
                            </td>
                            <td className="t-price">
                                <span className="h-12-500-i gr7">{globalFunction.withSpaces(globalFunction.split(el.total_price_with_discount)[0])} ₽</span>
                            </td>
                            <td className="t-icons">
                                <div>
                                    <button onClick={() => deleteRequest(el.id)}><img src={img_pack.articles_trash_icon} alt="trash_icon" /></button>
                                    <Link to={`${AdminOrderRedRootBase}${el.id}`}><img src={img_pack.articles_edit_icon} alt="edit_icon" /></Link>
                                </div>
                            </td>

                        </tr>
                    ))
                    }
                </tbody>
            </table>
            {totalPages > 1 &&
            <div className="t_foot">
                <button onClick={() => {setCurrentPage( ((currentPage - 1) >= 1) ? (currentPage - 1) : (currentPage) ); window.scrollTo(0, 0);}}>
                    <span className="prev_arrow_svg"/>
                    <span className="h-14-600-i">Предыдущее</span>
                </button>
                <Pagination totalPages={totalPages} pagesToShow={1} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                <button onClick={() => {setCurrentPage( ((currentPage + 1) <= totalPages) ? (currentPage + 1) : (currentPage) ); window.scrollTo(0, 0);}}>
                    <span className="h-14-600-i">Следующие</span>
                    <span className="next_arrow_svg"/>
                </button>
            </div>
            }
        </div>
    )
}

export default AdminOrderPage;