import React, { useContext, useEffect, useState } from "react"
import CustomDpd from "./Custom_dp_link";
import { Link, useNavigate } from "react-router-dom";
import GetStore from "../../API/GetStore";
import { useFetching } from "../../hooks/UseFetching";
import ProductCatChoice from "./ProductCatChoice";
import { StorePageRoot } from "../../utils/consts";
import { Context } from "../../App";

const BaseLsidebar = () => {

    const {allBrandsSide, allCatsSide} = useContext(Context)

    const [selectedCat, setSelectedCat] = useState({name: '', slug: ''});
    const [selectedBrand, setSelectedBrand] = useState({name: '', slug: ''});
    const navigate = useNavigate()

    useEffect(() => {
        if(selectedBrand?.slug !== '') {
            navigate(`${StorePageRoot}`, { state: { initialBrand: selectedBrand } })
        }
        if(selectedCat?.slug !== '') {
            navigate(`${StorePageRoot}`, { state: { initialCat: selectedCat } })
        }
    },[selectedCat?.slug, selectedBrand?.slug])

    return (
       <>
        <div className="base-lsidebar">
            <div className="base-lsidebar-col">

                <div className="h-16-700-i gr7">Категории товаров</div>
                <div className="col-10" style={{maxHeight: '300px', overflow: 'auto'}}>
                    {allCatsSide?.length > 0 && allCatsSide?.map((el) => (
                        <button className={`product_choicecat_btn h-14-600-i gr6 ${selectedCat?.slug === el.slug ? 'active' : ''}`} key={el.id} onClick={() => {selectedCat?.slug === el.slug ? setSelectedCat({name: '', slug: ''}) : setSelectedCat({name: el.name, slug: el.slug})}}>
                            <span>{el.name}</span>
                            <span className="ba-total">{el.product_total ? el.product_total : 0}</span>
                        </button>
                    ))}
                </div>
                <span style={{borderBottom: '2px solid #D0D5DD'}} />
                <div className="h-16-700-i gr7">Бренды</div>
                <div className="col-10" style={{maxHeight: '300px', overflow: 'auto'}}>
                    {allBrandsSide?.length > 0 && allBrandsSide?.map((el) => (
                        <button className={`product_choicecat_btn h-14-600-i gr6 ${selectedBrand?.slug === el.slug ? 'active' : ''}`} key={el.id} onClick={() => {selectedBrand?.slug === el.slug ? setSelectedBrand({name: '', slug: ''}) : setSelectedBrand({name: el.name, slug: el.slug})}}>
                            <span>{el.name}</span>
                            <span className="ba-total">{el.product_count ? el.product_count : 0}</span>
                        </button>
                    ))}
                </div>
            </div>
        </div>
       </>
    )
}

export default BaseLsidebar;