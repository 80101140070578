import React, { useEffect, useRef, useState } from "react"
import img_pack from "../../img/img"
import ToggleBtn from "./toggleBtn"
import EditorComponent from "./EditorCompoenent"
import globalFunction, { AdminStoreRedRootBase } from "../../utils/consts"
import { useLocation } from "react-router"
import CustomSelect from "./Custom_select"
import { useFetching } from "../../hooks/UseFetching"
import GetUsers from "../../API/GetUsers"

const UsersRedTabs = ({userRedTabsData, setUserRedTabsData}) => {

    const [usersRole, setUsersRole] = useState([])
    const [selectedBtn, setSelectedBtn] = useState('1')
    const location = useLocation()

    function menuBtnClick(e) {
        const numb = e.currentTarget.getAttribute('numb')
        if(selectedBtn !== numb) {
            setSelectedBtn(numb)
        }
    }

    const [GetUsersRoleAll] = useFetching( async (idParam) => {
        const res = await GetUsers.GetUsersRoleAll(idParam)
        // console.log('GetUsersRoleAll complete:' , res.data)
        setUsersRole(res.data.detail)
    })

    useEffect(() => {
        GetUsersRoleAll()
    },[])



    return (
        <div className="productRed_tabs">
            <div className="productRed_menu base_content_1" style={{width: 'max-content'}}>
                <button type="button" className={`productRed_menu_btn ${selectedBtn === '1' ? 'active' : ''}`} numb={1} onClick={menuBtnClick} style={{minWidth: '200px'}}>
                    <img_pack.user_icon />
                    <div className="h-14-500-i">Основные</div>
                </button>
                <button type="button" className={`productRed_menu_btn ${selectedBtn === '2' ? 'active' : ''}`} numb={2} onClick={menuBtnClick} style={{minWidth: '200px'}}>
                    <img_pack.truck_icon />
                    <div className="h-14-500-i">Доставка</div>
                </button>
                <button type="button" className={`productRed_menu_btn ${selectedBtn === '3' ? 'active' : ''}`} numb={3} onClick={menuBtnClick} style={{minWidth: '200px'}}>
                    <img_pack.lock_icon />
                    <div className="h-14-500-i">Пароли</div>
                </button>
            </div>
            {selectedBtn === '1' &&
            <div className="productRed_content base_content_1">
                <table>
                    <tbody>
                        <tr>
                            <td className="h-16-400-i">Фамилия</td>
                            <td><input name="last_name" type="text" className="base-input" value={userRedTabsData?.last_name || ''} onChange={(e) => setUserRedTabsData({...userRedTabsData, last_name: e.target.value})}/></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Имя</td>
                            <td><input name="first_name" type="text" className="base-input" value={userRedTabsData?.first_name || ''} onChange={(e) => setUserRedTabsData({...userRedTabsData, first_name: e.target.value})}/></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Отчество</td>
                            <td><input name="middle_name" type="text" className="base-input" value={userRedTabsData?.middle_name || ''} onChange={(e) => setUserRedTabsData({...userRedTabsData, middle_name: e.target.value})}/></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Email</td>
                            <td><input name="email" type="text" className="base-input" value={userRedTabsData?.email || ''} onChange={(e) => setUserRedTabsData({...userRedTabsData, email: e.target.value})}/></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Телефон</td>
                            <td><input name="phone" type="text" className="base-input" value={userRedTabsData?.phone || ''} onChange={(e) => setUserRedTabsData({...userRedTabsData, phone: e.target.value})}/></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Роль</td>
                            <td>
                                <CustomSelect allOption={usersRole} ActiveItems={userRedTabsData.role} setActiveItems={(e) => setUserRedTabsData({...userRedTabsData, role: e})} single={true} enclosure={false} placeholder={'Выбрать роль'}/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            }
            {selectedBtn === '2' &&
            <>
            <div className="productRed_content base_content_1">
                <table>
                    <tbody>
                        <tr>
                            <td className="h-16-400-i">Город</td>
                            <td><input name="city" type="text" className="base-input" value={userRedTabsData?.city || ''} onChange={(e) => setUserRedTabsData({...userRedTabsData, city: e.target.value})}/></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Индекс</td>
                            <td><input name="postal_code" type="text" className="base-input" value={userRedTabsData?.postal_code || ''} onChange={(e) => setUserRedTabsData({...userRedTabsData, postal_code: e.target.value})}/></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Адрес</td>
                            <td><input name="address" type="text" className="base-input" value={userRedTabsData?.address || ''} onChange={(e) => setUserRedTabsData({...userRedTabsData, address: e.target.value})}/></td>
                        </tr>
   
                    </tbody>
                </table>
            </div>
            </>
            }
            {selectedBtn === '3' &&
            <>
            <div className="productRed_content base_content_1">
                <table>
                    <tbody>
                        <tr>
                            <td className="h-16-400-i">Пароль</td>
                            <td><input name="password" type="text" className="base-input" value={userRedTabsData?.password || ''} onChange={(e) => setUserRedTabsData({...userRedTabsData, password: e.target.value})}/></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
            }
                 
        </div>
    )
}

export default UsersRedTabs;