import React, { useContext, useEffect, useState } from "react"
import CustomSelect from "./Custom_select";
import { useLocation } from "react-router";
import { useByCarConfigurator } from "../../hooks/UseByCarConfiguration";
import { useByParamsConfigurator } from "../../hooks/UseByParamsConfiguration";
import { useFetching } from "../../hooks/UseFetching";
import GetConfigurator from "../../API/GetConfigurator";

const SotreSearchBlock = ({AllParams, setAllParams, setSelectedCat, setSelectedBrand }) => {

    const location = useLocation()
    let searchInitialData = location.state?.searchInitialData

    const [selected1, setSelected1] = useState(location.state?.searchInitialData?.selected1 || false)
    const [selected2, setSelected2] = useState(false)

    const [shouldSort, setShouldSort] = useState(false);
    
    const {allTiresMarks,allTiresModels,allTiresYear,allTiresTrim,allDisksMarks,allDisksModels,allDisksYear,allDisksTrim,dataByCarTires, setDataByCarTires, dataByCarDiscs, setDataByCarDiscs} = useByCarConfigurator({selected1})
    const {allDisksType,prevSelectedSubcategoryAttrsRef,tiresAttrLoading,disksAttrLoading,allSubcategoryAttrs,selectedSubcategoryAttrs,setSelectedSubcategoryAttrs,allTiresWidth,allTiresProfile,allTiresDiameter,allTiresSeasonality,allTiresType,allTiresManufacturer,allDisksWidth,allDisksDiameter,allDisksPCD,allDisksET,allDisksHub,allDisksManufacturer,dataByParamTires, setDataByParamTires, dataByParamDiscs, setDataByParamDiscs} = useByParamsConfigurator({selected1})

    // Получение всех вариантов (Автомобиль)
    const [GetConfiguratorItems] = useFetching( async (data, sel) => {
        const res = await GetConfigurator.GetConfiguratorItems(data)
        // console.log('GetConfiguratorItems complete:' , res.data)
        if(!sel) {
            const width = res.data.result[0]?.width
            const profile = res.data.result[0]?.aspect_ratio
            const diameter = res.data.result[0]?.diameter
            setDataByParamTires(prevState => ({
                ...prevState,
                width: [{value: width, name: width}] || prevState.width,
                profile: [{value: profile, name: profile}] || prevState.profile,
                diameter: [{value: diameter, name: diameter}] || prevState.diameter,
            }));
            setShouldSort(true)
        } else {
            const width = res.data.result[0]?.width
            const diameter = res.data.result[0]?.diameter
            const pcd = res.data.result[0]?.pcd
            const et = res.data.result[0]?.et
            const hub_diameter = res.data.result[0]?.hub_diameter
            setDataByParamDiscs(prevState => ({
                ...prevState,
                width: [{value: width, name: width}] || prevState.width,
                diameter: [{value: diameter, name: diameter}] || prevState.diameter,
                pcd: [{value: pcd, name: pcd}] || prevState.pcd,
                et: [{value: et, name: et}] || prevState.et,
                hub: [{value: hub_diameter, name: hub_diameter}] || prevState.hub,
            }));
            setShouldSort(true)
        }
        setSelected2(false)
    })

    function GetByCarParams() {
        const data = new FormData();
        data.append("good_type", selected1 ? 'disk' : 'tire');
        data.append("brand", !selected1 ? dataByCarTires.stamp[0]?.value : dataByCarDiscs.stamp[0]?.value);
        data.append("model", !selected1 ? dataByCarTires.model[0]?.value : dataByCarDiscs.model[0]?.value);
        data.append("years", !selected1 ? dataByCarTires.year[0]?.value : dataByCarDiscs.year[0]?.value);
        data.append("trim", !selected1 ? dataByCarTires.engine[0]?.value : dataByCarDiscs.engine[0]?.value);
        GetConfiguratorItems(data, selected1)
    }

    function submit() {
        if(!selected1) {
            if(selected2) {
                if(dataByCarTires.engine.length > 0) {
                    GetByCarParams()
                }
            } else {
                saveParams()
            }
        } else {
            if(selected2) {
                if(dataByCarDiscs.engine.length > 0) {
                    GetByCarParams()
                }
            } else {
                saveParams()
            }
        }
    }

    useEffect(() => {
        // console.log('searchInitialData',searchInitialData)
        if(searchInitialData?.selected1 !== undefined) {
            setSelected1(searchInitialData.selected1)
        }
        if(searchInitialData) {
            if(searchInitialData?.selectedSubcategoryAttrs) {
                prevSelectedSubcategoryAttrsRef.current = searchInitialData?.selectedSubcategoryAttrs;
                setSelectedSubcategoryAttrs(searchInitialData?.selectedSubcategoryAttrs)
            }
            if(!searchInitialData?.selected1) {
                if(searchInitialData?.selected2) {
                    if(searchInitialData?.itemsResult?.length >0) {
                        const width = searchInitialData?.itemsResult[0]?.width
                        const profile = searchInitialData?.itemsResult[0]?.aspect_ratio
                        const diameter = searchInitialData?.itemsResult[0]?.diameter
                        setDataByParamTires(prevState => ({
                            ...prevState,
                            width: [{value: width, name: width}] || prevState.width,
                            profile: [{value: profile, name: profile}] || prevState.profile,
                            diameter: [{value: diameter, name: diameter}] || prevState.diameter,
                        }));
                        setShouldSort(true)
                    }
                } else {
                    setDataByParamTires(searchInitialData?.data)
                    setShouldSort(true)
                }
            } else {
                if(searchInitialData?.selected2) {
                    if(searchInitialData?.itemsResult?.length >0) {
                        const width = searchInitialData?.itemsResult[0]?.width
                        const diameter = searchInitialData?.itemsResult[0]?.diameter
                        const pcd = searchInitialData?.itemsResult[0]?.pcd
                        const et = searchInitialData?.itemsResult[0]?.et
                        const hub_diameter = searchInitialData?.itemsResult[0]?.hub_diameter
                        setDataByParamDiscs(prevState => ({
                            ...prevState,
                            width: [{value: width, name: width}] || prevState.width,
                            diameter: [{value: diameter, name: diameter}] || prevState.diameter,
                            pcd: [{value: pcd, name: pcd}] || prevState.pcd,
                            et: [{value: et, name: et}] || prevState.et,
                            hub: [{value: hub_diameter, name: hub_diameter}] || prevState.hub,
                        }));
                        setShouldSort(true)
                    }
                } else {
                    setDataByParamDiscs(searchInitialData?.data)
                    setShouldSort(true)
                }
            }
        }
        // Обнуление состояния в истории
        searchInitialData = ''
        window.history.replaceState({}, '')
    },[searchInitialData])

    useEffect(() => {
        if(shouldSort) {
            saveParams()
            setShouldSort(false)
        }
    },[dataByParamDiscs])
    useEffect(() => {
        if(shouldSort) {
            saveParams()
            setShouldSort(false)
        }
    },[dataByParamTires])

    function saveParams() {
        selectedSubcategoryAttrs?.[0]?.value ? setSelectedCat({ name: selectedSubcategoryAttrs[0].name, slug: selectedSubcategoryAttrs[0].value }) : setSelectedCat({ name: '', slug: '' })
        setSelectedBrand(dataByParamTires?.manufacturer?.[0]?.value ? { name: dataByParamTires?.manufacturer?.[0]?.name, slug: dataByParamTires?.manufacturer?.[0]?.value } : { name: dataByParamDiscs?.manufacturer?.[0]?.name, slug: dataByParamDiscs?.manufacturer?.[0]?.value } || { name: '', slug: '' })
        setAllParams({
            selectedSubcategoryAttrs: selectedSubcategoryAttrs,
            selected1: selected1,
            tires: dataByParamTires,
            disks: dataByParamDiscs,
        })
    }

    return (
        <>
            <div className="h-16-700-i gr7">Подбор шин и дисков</div>
            <div className="dual-btn">
                <button className={`h-14-400-j ${!selected1 ? 'active white' : ''}`} onClick={() => setSelected1(false)}>Шины</button>
                <button className={`h-14-400-j ${selected1 ? 'active white' : ''}`} onClick={() => setSelected1(true)}>Диски</button>
            </div>
            <div className="dual-btn">
                <button className={`h-14-400-j ${!selected2 ? 'active white' : ''}`} onClick={() => setSelected2(false)}>По параметрам</button>
                <button className={`h-14-400-j ${selected2 ? 'active white' : ''}`} onClick={() => setSelected2(true)}>По автомобилю</button>
            </div>
            {!selected2 &&
                <CustomSelect allOption={allSubcategoryAttrs} ActiveItems={selectedSubcategoryAttrs || ''} setActiveItems={(e) => setSelectedSubcategoryAttrs(e)} single={true} enclosure={false} placeholder={'Выберите тип'} disabled={!(allSubcategoryAttrs.length>0)}/>
            }
          
            {!selected1 ?
                (!selected2 ?
                <>
                    <CustomSelect allOption={allTiresWidth} ActiveItems={dataByParamTires?.width || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, width: e})} single={true} enclosure={false} placeholder={'Ширина, мм'} disabled={!(allTiresWidth.length > 0 && selectedSubcategoryAttrs[0]?.value && !tiresAttrLoading)}/>
                    <CustomSelect allOption={allTiresProfile} ActiveItems={dataByParamTires?.profile || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, profile: e})} single={true} enclosure={false} placeholder={'Профиль'} disabled={!(allTiresProfile.length > 0 && selectedSubcategoryAttrs[0]?.value && !tiresAttrLoading)}/>
                    <CustomSelect allOption={allTiresDiameter} ActiveItems={dataByParamTires?.diameter || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, diameter: e})} single={true} enclosure={false} placeholder={'Диаметр'} disabled={!(allTiresDiameter.length > 0 && selectedSubcategoryAttrs[0]?.value && !tiresAttrLoading)}/>
                    <CustomSelect allOption={allTiresSeasonality} ActiveItems={dataByParamTires?.seasonality || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, seasonality: e})} single={true} enclosure={false} placeholder={'Сезонность'} disabled={!(allTiresSeasonality.length > 0 && selectedSubcategoryAttrs[0]?.value && !tiresAttrLoading)}/>
                    <CustomSelect allOption={allTiresType} ActiveItems={dataByParamTires?.type || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, type: e})} single={true} enclosure={false} placeholder={'Шипы'} disabled={!(allTiresType.length > 0 && selectedSubcategoryAttrs[0]?.value && !tiresAttrLoading)}/>
                    <CustomSelect allOption={allTiresManufacturer} ActiveItems={dataByParamTires?.manufacturer || ''} setActiveItems={(e) => setDataByParamTires({...dataByParamTires, manufacturer: e})} single={true} enclosure={false} placeholder={'Производитель'} disabled={!(allTiresManufacturer.length > 0 && selectedSubcategoryAttrs[0]?.value && !tiresAttrLoading)}/>
                </>
                :
                <>
                    <CustomSelect allOption={allTiresMarks} ActiveItems={dataByCarTires?.stamp || ''} setActiveItems={(e) => setDataByCarTires({...dataByCarTires, stamp: e})} single={true} enclosure={false} placeholder={'Марка'} disabled={!(allTiresMarks.length>0)}/>
                    <CustomSelect allOption={allTiresModels} ActiveItems={dataByCarTires?.model || ''} setActiveItems={(e) => setDataByCarTires({...dataByCarTires, model: e})} single={true} enclosure={false} placeholder={'Модель'} disabled={!(dataByCarTires?.stamp.length > 0 && allTiresModels.length>0)}/>
                    <CustomSelect allOption={allTiresYear} ActiveItems={dataByCarTires?.year || ''} setActiveItems={(e) => setDataByCarTires({...dataByCarTires, year: e})} single={true} enclosure={false} placeholder={'Год выпуска'} disabled={!(dataByCarTires?.model.length > 0 && allTiresYear.length>0)}/>
                    <CustomSelect allOption={allTiresTrim} ActiveItems={dataByCarTires?.engine || ''} setActiveItems={(e) => setDataByCarTires({...dataByCarTires, engine: e})} single={true} enclosure={false} placeholder={'Двигатель'} disabled={!(dataByCarTires?.year.length > 0 && allTiresTrim.length>0)}/>
                </>)
            :
                (!selected2 ?
                <>
                    <CustomSelect allOption={allDisksWidth} ActiveItems={dataByParamDiscs?.width || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, width: e})} single={true} enclosure={false} placeholder={'Ширина, дюйм'} disabled={!(allDisksWidth.length > 0 && selectedSubcategoryAttrs[0]?.value && !disksAttrLoading)}/>
                    <CustomSelect allOption={allDisksDiameter} ActiveItems={dataByParamDiscs?.diameter || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, diameter: e})} single={true} enclosure={false} placeholder={'Диаметр, дюйм'} disabled={!(allDisksDiameter.length > 0 && selectedSubcategoryAttrs[0]?.value && !disksAttrLoading)}/>
                    <CustomSelect allOption={allDisksPCD} ActiveItems={dataByParamDiscs?.pcd || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, pcd: e})} single={true} enclosure={false} placeholder={'PCD (крепёж)'} disabled={!(allDisksPCD.length > 0 && selectedSubcategoryAttrs[0]?.value && !disksAttrLoading)}/>
                    <CustomSelect allOption={allDisksET} ActiveItems={dataByParamDiscs?.et || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, et: e})} single={true} enclosure={false} placeholder={'ET (вылет)'} disabled={!(allDisksET.length > 0 && selectedSubcategoryAttrs[0]?.value && !disksAttrLoading)}/>
                    <CustomSelect allOption={allDisksHub} ActiveItems={dataByParamDiscs?.hub || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, hub: e})} single={true} enclosure={false} placeholder={'Ступица'} disabled={!(allDisksHub.length > 0 && selectedSubcategoryAttrs[0]?.value && !disksAttrLoading)}/>
                    <CustomSelect allOption={allDisksType} ActiveItems={dataByParamDiscs?.type || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, type: e})} single={true} enclosure={false} placeholder={'Тип'} disabled={!(allDisksType.length > 0 && selectedSubcategoryAttrs[0]?.value && !disksAttrLoading)}/>
                    <CustomSelect allOption={allDisksManufacturer} ActiveItems={dataByParamDiscs?.manufacturer || ''} setActiveItems={(e) => setDataByParamDiscs({...dataByParamDiscs, manufacturer: e})} single={true} enclosure={false} placeholder={'Производитель'} disabled={!(allDisksManufacturer.length > 0 && selectedSubcategoryAttrs[0]?.value && !disksAttrLoading)}/>
                </>
                :
                <>
                    <CustomSelect allOption={allDisksMarks} ActiveItems={dataByCarDiscs?.stamp || ''} setActiveItems={(e) => setDataByCarDiscs({...dataByCarDiscs, stamp: e})} single={true} enclosure={false} placeholder={'Марка'} disabled={!(allDisksMarks.length>0)}/>
                    <CustomSelect allOption={allDisksModels} ActiveItems={dataByCarDiscs?.model || ''} setActiveItems={(e) => setDataByCarDiscs({...dataByCarDiscs, model: e})} single={true} enclosure={false} placeholder={'Модель'} disabled={!(dataByCarDiscs?.stamp.length > 0 && allDisksModels.length>0)}/>
                    <CustomSelect allOption={allDisksYear} ActiveItems={dataByCarDiscs?.year || ''} setActiveItems={(e) => setDataByCarDiscs({...dataByCarDiscs, year: e})} single={true} enclosure={false} placeholder={'Год выпуска'} disabled={!(dataByCarDiscs?.model.length > 0 && allDisksYear.length>0)}/>
                    <CustomSelect allOption={allDisksTrim} ActiveItems={dataByCarDiscs?.engine || ''} setActiveItems={(e) => setDataByCarDiscs({...dataByCarDiscs, engine: e})} single={true} enclosure={false} placeholder={'Двигатель'} disabled={!(dataByCarDiscs?.year.length > 0 && allDisksTrim.length>0)}/>
                </>)

            }
            <button className="base-btn white h-14-400-j" onClick={submit}>Найти</button>
        </>
    )
}

export default SotreSearchBlock;