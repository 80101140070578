import AdminContactPage from "../Pages/AdminContactPage";
import AdminContactRedPage from "../Pages/AdminContactRedPage";
import AdminContactSettingsPage from "../Pages/AdminContactSettingsPage";
import AdminGeneralPage from "../Pages/AdminGeneralPage";
import AdminHelpPage from "../Pages/AdminHelpPage";
import AdminMediafilesPage from "../Pages/AdminMediafilesPage";
import AdminMediafilesRedPage from "../Pages/AdminMediafilesRedPage";
import AdminOrderPage from "../Pages/AdminOrderPage";
import AdminOrderRedPage from "../Pages/AdminOrderRedPage";
import AdminOrderSettings from "../Pages/AdminOrderSettings";
import AdminSettingsPage from "../Pages/AdminSettings";
import AdminStoreBrandCatPage from "../Pages/AdminStoreBrandCatPage";
import AdminStoreBrandCatRedPage from "../Pages/AdminStoreBrandCatRedPage";
import AdminStoreBrandsPage from "../Pages/AdminStoreBrandsPage";
import AdminStoreBrandsRedPage from "../Pages/AdminStoreBrandsRedPage";
import AdminStoreCatPage from "../Pages/AdminStoreCatPage";
import AdminStoreCatRedPage from "../Pages/AdminStoreCatRedPage";
import AdminStoreCouponsPage from "../Pages/AdminStoreCouponsPage";
import AdminStoreCouponsRedPage from "../Pages/AdminStoreCouponsRedPage";
import AdminStoreImportPage from "../Pages/AdminStoreImportPage";
import AdminStorePage from "../Pages/AdminStorePage";
import AdminStoreRedPage from "../Pages/AdminStoreRedPage";
import AdminUsersPage from "../Pages/AdminUsersPage";
import AdminUsersRedPage from "../Pages/AdminUsersRedPage";
import AdminUsersRolesPage from "../Pages/AdminUsersRolesPage";
import AdminUsersRolesRedPage from "../Pages/AdminUsersRolesRedPage";
import Auth from "../Pages/Auth";
import CartOrderPage from "../Pages/CartOrderPage";
import CartPage from "../Pages/CartPage";
import ExchangeAndRefundPage from "../Pages/ExchangeAndRefundPage";
import FaqPage from "../Pages/FaqPage";
import OrderPage from "../Pages/OrderPage";
import Page404 from "../Pages/Page404";
import PaymentAndDeliveryPage from "../Pages/PaymentAndDeliveryPage";
import PrivacyPolicyPage from "../Pages/PrivacyPolicyPage";
import ProductsPage from "../Pages/ProductsPage";
import StorePage from "../Pages/StorePage";
import { AdminContactRedRoot, AdminContactRoot, AdminContactSettingsRoot, AdminGeneralRoot, AdminHelpPageRoot, AdminMediafilesRedRoot, AdminMediafilesRoot, AdminOrderRedRoot, AdminOrderRoot, AdminOrderSettingsRoot, AdminSettingsRoot, AdminStoreBrandsCatNewRoot, AdminStoreBrandsCatRedRoot, AdminStoreBrandsCatRoot, AdminStoreBrandsNewRoot, AdminStoreBrandsRedRoot, AdminStoreBrandsRoot, AdminStoreCatNewRoot, AdminStoreCatRedRoot, AdminStoreCatRoot, AdminStoreCouponsNewRoot, AdminStoreCouponsRedRoot, AdminStoreCouponsRoot, AdminStoreImportRoot, AdminStoreNewRoot, AdminStoreRedRoot, AdminStoreRoot, AdminUsersNewRoot, AdminUsersRedRoot, AdminUsersRolesNewRoot, AdminUsersRolesRedRoot, AdminUsersRolesRoot, AdminUsersRoot, Auth_Root, BlogPageRoot, CartOrderPageRoot, CartPageRoot, Email_verify_Root, ExchangeAndRefundRoot, FaqPageRoot, Login_Root, OrderPageRoot, Page404_Root, Password_new_Root, Password_recovery_Root, PaymentAndDeliveryRoot, PostPageRoot, PrivacyPolicyRoot, ProductPageRoot, PublicOfferRoot, Registration_Root, StorePageRoot } from "./consts";

export const publicRoutes = [
    {
        path: Login_Root,
        element: <Auth />,
    },
    // {
    //     path: Registration_Root,
    //     element: <Auth />,
    // },
    // {
    //     path: Password_recovery_Root,
    //     element: <Auth />,
    // },
    // {
    //     path: Password_new_Root,
    //     element: <Auth />,
    // },
    // {
    //     path: Email_verify_Root,
    //     element: <Auth />,
    // },

    // {
    //     path: Page404_Root,
    //     element: <Page404 />
    // },
    // {
    //     path: Admin_Root,
    //     element: <Admin_page />
    // },
]

export const adminRoutes = [
    {
        path: AdminGeneralRoot,
        element: <AdminGeneralPage />,
    },

    {
        path: AdminStoreImportRoot,
        element: <AdminStoreImportPage />,
    },

    {
        path: AdminSettingsRoot,
        element: <AdminSettingsPage />,
    },

    {
        path: AdminUsersRoot,
        element: <AdminUsersPage />,
    },
    {
        path: AdminUsersNewRoot,
        element: <AdminUsersRedPage />,
    },
    {
        path: AdminUsersRedRoot,
        element: <AdminUsersRedPage />,
    },

    {
        path: AdminUsersRolesRoot,
        element: <AdminUsersRolesPage />,
    },
    {
        path: AdminUsersRolesNewRoot,
        element: <AdminUsersRolesRedPage />,
    },
    {
        path: AdminUsersRolesRedRoot,
        element: <AdminUsersRolesRedPage />,
    },

    {
        path: AdminOrderRoot,
        element: <AdminOrderPage />,
    },
    {
        path: AdminOrderRedRoot,
        element: <AdminOrderRedPage />,
    },
    {
        path: AdminOrderSettingsRoot,
        element: <AdminOrderSettings />,
    },

    {
        path: AdminContactRoot,
        element: <AdminContactPage />,
    },
    {
        path: AdminContactRedRoot,
        element: <AdminContactRedPage />,
    },
    
    {
        path: AdminContactSettingsRoot,
        element: <AdminContactSettingsPage />,
    },

    {
        path: AdminStoreRoot,
        element: <AdminStorePage />,
    },
    {
        path: AdminStoreNewRoot,
        element: <AdminStoreRedPage />,
    },
    {
        path: AdminStoreRedRoot,
        element: <AdminStoreRedPage />,
    },

    {
        path: AdminStoreCatRoot,
        element: <AdminStoreCatPage />,
    },
    {
        path: AdminStoreCatNewRoot,
        element: <AdminStoreCatRedPage />,
    },
    {
        path: AdminStoreCatRedRoot,
        element: <AdminStoreCatRedPage />,
    },

    {
        path: AdminStoreCouponsRoot,
        element: <AdminStoreCouponsPage />,
    },
    {
        path: AdminStoreCouponsNewRoot,
        element: <AdminStoreCouponsRedPage />,
    },
    {
        path: AdminStoreCouponsRedRoot,
        element: <AdminStoreCouponsRedPage />,
    },

    {
        path: AdminStoreBrandsRoot,
        element: <AdminStoreBrandsPage />,
    },
    {
        path: AdminStoreBrandsNewRoot,
        element: <AdminStoreBrandsRedPage />,
    },
    {
        path: AdminStoreBrandsRedRoot,
        element: <AdminStoreBrandsRedPage />,
    },

    {
        path: AdminStoreBrandsCatRoot,
        element: <AdminStoreBrandCatPage />,
    },
    {
        path: AdminStoreBrandsCatNewRoot,
        element: <AdminStoreBrandCatRedPage />,
    },
    {
        path: AdminStoreBrandsCatRedRoot,
        element: <AdminStoreBrandCatRedPage />,
    },

    {
        path: AdminMediafilesRoot,
        element: <AdminMediafilesPage />,
    },
    {
        path: AdminMediafilesRedRoot,
        element: <AdminMediafilesRedPage />,
    },

    {
        path: AdminHelpPageRoot,
        element: <AdminHelpPage />,
    },
]

export const otherRoutes = [
    {
        path: OrderPageRoot,
        element: <OrderPage />,
    },

    {
        path: CartPageRoot,
        element: <CartPage />,
    },
    {
        path: CartOrderPageRoot,
        element: <CartOrderPage />,
    },

    {
        path: FaqPageRoot,
        element: <FaqPage />,
    },
    {
        path: PaymentAndDeliveryRoot,
        element: <PaymentAndDeliveryPage />,
    },
    {
        path: ExchangeAndRefundRoot,
        element: <ExchangeAndRefundPage />,
    },
    {
        path: PrivacyPolicyRoot,
        element: <PrivacyPolicyPage />,
    },

    {
        path: StorePageRoot,
        element: <StorePage />,
    },
    {
        path: ProductPageRoot,
        element: <ProductsPage />,
    },

    {
        path: Page404_Root,
        element: <Page404 />,
    },

]